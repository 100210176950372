export default () => ({
    //map result
    //first one is resolved postal address
    //second one is coord [lat, lon] of the selected point
    postalAddress: null,
    location: null,

    //Instance of LocationService
    resolver: null,

    //Used to show places from postal address
    locationsResult:[],

    //map config
    config: {
        type: null,
        zoom: 11,
        center: null,
        poisConfig:null,
        cityRestrictConfig:null,
    },

    //map points and polygons
    pois: [],

    //map restriction
    cities: [],
    options: {
        invert: true
    },
});
