<template>
  <header class="page-header" role="banner">
    <!-- we need this logo when user switches to nav-function-top -->
    <div class="page-logo">
      <router-link
        class="page-logo-link press-scale-down d-flex align-items-center position-relative"
        data-target="#modal-shortcut"
        data-toggle="modal"
        to="/"
      >
        <img
          alt="Bouge Ma Ville"
          aria-roledescription="logo"
          src="/img/logo.png"
        />
        <span class="page-logo-text mr-1">Bouge Ma Ville</span>
        <span
          class="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2"
        ></span>
        <i
          class="fal fa-angle-down d-inline-block ml-1 fs-lg color-primary-300"
        ></i>
      </router-link>
    </div>

    <!-- DOC: nav menu layout change shortcut -->
    <div class="hidden-lg-up">
      <a
        class="btn bg-blue-600 px-4 py-2 press-scale-down waves-effect waves-themed"
        data-action="toggle"
        data-class="mobile-nav-on"
        href="#"
      >
        <i class="ni ni-menu text-white text-xl"></i>
      </a>
    </div>
    <div class="hidden-md-down justify-content-center align-middle">
      <div class="subheader hidden-md-down">
        <portal-target name="ariane"></portal-target>
      </div>
    </div>

    <!-- DOC: mobile button appears during mobile width -->
    <div class="ml-auto d-flex">
      <!-- app user menu -->
      <Create />
      <Export />
      <Profil />
    </div>
  </header>
</template>

<script>
import LanguageSwitch from './LanguageSwitch.vue'
import Export from './ButtonExportPosts.vue'
import Create from './ButtonCreate.vue'
import Profil from './Profil.vue'
import AppShortcut from './AppShortcut.vue'

export default {
  components: {
    LanguageSwitch,
    AppShortcut,
    Profil,
    Create,
    Export
  },
  props: ['logo', 'baseline'],
  created() {},
  data: function () {
    return {}
  },
  methods: {
  },
  mounted() {},
}
</script>
