<script>
import {mapActions,mapState} from 'vuex'

export default {
  name: 'FormItemMixin',
  props: {
    attribute: {
      required: true,
      type: Object,
      description: 'An attribute that need to be validated'
    },
    attributeIndex: {
      required: true,
      type: Number,
      description:
          'Current attribute index from the attributes array in FormStep'
    },
    stepIndex: {
      required: true,
      type: Number,
      description: 'Index of the step that owns this attribute'
    }
  },
  watch: {
    result: function () {
      if (this.wasValidated) {
        if (this.isValid()) {
          this.message = ''
        } else {
          this.message = 'Ce champ est requis'
        }
      }
    },
    wasValidated: function (isValidated) {
      if (isValidated) {
        if (!this.isValid()) {
          this.message = 'Ce champ est requis'
          if (
              this.attribute.form_attribute_type_id === 16 &&
              !this.mailregExp.test(this.result)
          ) {
            this.message = this.$t('error.validation.mail_valid')
          }
        }
      }
    }
  },
  data() {
    return {
      result: null,
      wasValidated: false,
      asterisk: this.attribute.requiredForWizard === 1 ? '*' : '',
      requiredasterisk: '',
      requiredmessage: '',
      fileexist: false,
      message: '',
      mailregExp:
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    }
  },
  created() {
    if(this.packageForReborn){
      this.initialize()
    }
    this.requirefields()
  },
  computed: {
    ...mapState('wizard', {
      packageForReborn: state => state.packageForReborn
    }),
  },
  methods: {
    ...mapActions('post', {
      saveUserAnswer: 'saveUserAnswer'
    }),
    shouldInitialize() {
      if (
          this.$store.state.post.post &&
          this.$store.state.post.post.attributes
      ) {
        if (this.attribute && this.attribute.id) {
          if (
              this.$store.state.post.post.attributes.hasOwnProperty(
                  this.attribute.id
              )
          ) {
            return true
          }
        }
      }

      return false
    },
    initialize() {
      if (this.shouldInitialize()) {
        this.saveUserAnswer({
          id: this.attribute.id,
          type_id: this.attribute.form_attribute_type_id,
          value: this.$store.state.post.post.attributes[
              this.attribute.id
              ].value
        }).then(() => {
          if (
              [13, 6].includes(
                  this.$store.state.post.post.attributes[
                      this.attribute.id
                      ].type_id
              )
          ) {
            this.result =
                this.$store.state.post.post.attributes[
                    this.attribute.id
                    ]
          } else {
            this.result =
                this.$store.state.post.post.attributes[
                    this.attribute.id
                    ].value
          }
        })
      }
    },
    requirefields() {
      if (this.attribute.requiredForWizard === 1) {
        if (
            this.attribute.form_attribute_type_id === 13 ||
            this.attribute.form_attribute_type_id === 6
        ) {
          if (this.attribute.instruction) {
            this.requiredasterisk = '*'
            this.asterisk = ''
          } else {
            this.requiredmessage =
                '* ' + this.$t('error.validation.required')
            this.requiredasterisk = '  '
          }
        } else {
          if (this.attribute.label) {
            this.requiredasterisk = ''
            this.asterisk = '*'
          } else if (this.attribute.instruction) {
            this.requiredasterisk = '*'
            this.asterisk = ''
          } else {
            this.requiredmessage =
                '* ' + this.$t('error.validation.required')
            this.requiredasterisk = '  '
          }
        }
      }
    },
    doEmit(answer) {
      this.saveUserAnswer({
        id: this.attribute.id,
        type_id: this.attribute.form_attribute_type_id,
        value: answer.value
      }).then(() => {
        this.result =
            this.$store.state.post.post.attributes[this.attribute.id]
      })
    },
    doSave(answer) {
      if (this.attribute.config && this.attribute.config.as) {
        answer.as = this.attribute.config.as
      }

      if (!Object.prototype.hasOwnProperty.call(answer, 'type_id')) {
        answer.type_id = this.attribute.form_attribute_type_id
      }
      this.saveUserAnswer(answer).then(() => {
        this.result =
            this.$store.state.post.post.attributes[
                this.attribute.id
                ].value
      })
    },
    validate() {
      this.wasValidated = true
      return this.isValid()
    },
    isValid() {
      if (this.attribute.requiredForWizard === 1) {
        if (
            this.attribute.form_attribute_type_id == 6 &&
            (this.result.value.address === '' || this.result.value.lat === null || this.result.value.long === null)
        ) {
          return false
        } else if (
            this.attribute.form_attribute_type_id === 12 &&
            this.result.length == 0
        ) {
          return false
        } else if (
            this.attribute.form_attribute_type_id === 13 &&
            (this.result === null || this.result && this.result.value === undefined)
        ) {
          return false
        } else if (
            this.attribute.form_attribute_type_id === 16 &&
            !this.mailregExp.test(this.result)
        ) {
          return false
        } else {
          return !!this.result
        }
      } else {
        return true
      }
    }
  }
}
</script>
