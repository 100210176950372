<template>
  <section>
    <HeaderFormItem
        :instruction="`${
                attribute.instruction ? attribute.instruction : ''
            } ${requiredasterisk}`"
        :description="`${
                attribute.description ? attribute.description : ''
            } `"
        :required="`${requiredmessage} `"
    >
    </HeaderFormItem>
    <div class="flex items-center w-full">
      <i
          :class="
                    attribute.response_private == 1
                        ? 'fas fa-lock px-1 pt-5'
                        : ' '
                "
      ></i>
      <b-field
          :label="`${attribute.label ? attribute.label : ''} ${asterisk}`"
          class="dateField"
          :message="attribute.help ? attribute.help : ''"
      >
        <b-datepicker
            class="dateInput"
            v-model="selected"
            :placeholder="
                        attribute.config.placeholder &&
                        attribute.config.placeholder.length > 0
                            ? attribute.config.placeholder[this.$i18n.locale]
                            : ''
                    "
            icon="calendar-today"
            :icon-right="selected ? 'close-circle' : ''"
            icon-right-clickable
            @icon-right-click="clearDate"
            :locale="locale"
            :first-day-of-week="firstDayOfWeek"
            trap-focus
        >
        </b-datepicker>
      </b-field>
    </div>
    <b-field :message="message" type="is-danger font-bold text-center">
    </b-field>
  </section>
</template>

<script>
import FormItemMixin from '../FormItemMixin'
import HeaderFormItem from '../HeaderFormItem'
import moment from 'moment'

export default {
  name: 'DateFormItem',
  components: { HeaderFormItem },
  mixins: [FormItemMixin],
  data() {
    return {
      requiredasterisk: '',
      selected: null,
      firstDayOfWeek :1,
    }
  },
  created() {
    if (this.shouldInitialize()) {
      this.saveUserAnswer({
        id: this.attribute.id,
        type_id: this.attribute.form_attribute_type_id,
        value: this.$store.state.post.post.attributes[this.attribute.id]
            .value
      }).then(() => {
        this.selected = new Date(
            this.$store.state.post.post.attributes[
                this.attribute.id
                ].value
        )
      })
    } else {
      this.clearDate()
    }
  },
  computed: {
    locale() {
      if (this.$i18n.locale == 'fr') {
        this.firstDayOfWeek = 1
        return 'fr-FR'
      } else {
        this.firstDayOfWeek = 0
        return 'en-US'
      }
    }
  },
  watch: {
    selected: function (newValue) {
      if (
          isNaN(newValue) ||
          newValue === 'Invalid date' ||
          newValue === null
      ) {
        this.clearDate()
      } else {
        let newDate = moment(newValue).format('YYYY-MM-DD')
        this.result = this.doSave({
          id: this.attribute.id,
          type_id: this.attribute.form_attribute_type_id,
          value: newDate
        })
        return newValue
      }
    }
  },
  methods: {
    clearDate() {
      this.selected = null
      this.result = null
    }
  }
}
</script>