<template>
  <p id="js-color-profile" class="d-none">
    <span class="color-primary"></span>
    <span class="color-primary"></span>
    <span class="color-primary"></span>
    <span class="color-primary"></span>
    <span class="color-primary"></span>
    <span class="color-primary0"></span>
    <span class="color-primary"></span>
    <span class="color-primary"></span>
    <span class="color-primary"></span>
    <span class="color-primary"></span>
    <span class="color-info"></span>
    <span class="color-info"></span>
    <span class="color-info"></span>
    <span class="color-info"></span>
    <span class="color-info"></span>
    <span class="color-info0"></span>
    <span class="color-info"></span>
    <span class="color-info"></span>
    <span class="color-info"></span>
    <span class="color-info"></span>
    <span class="color-danger"></span>
    <span class="color-danger"></span>
    <span class="color-danger"></span>
    <span class="color-danger"></span>
    <span class="color-danger"></span>
    <span class="color-danger0"></span>
    <span class="color-danger"></span>
    <span class="color-danger"></span>
    <span class="color-danger"></span>
    <span class="color-danger"></span>
    <span class="color-warning"></span>
    <span class="color-warning"></span>
    <span class="color-warning"></span>
    <span class="color-warning"></span>
    <span class="color-warning"></span>
    <span class="color-warning0"></span>
    <span class="color-warning"></span>
    <span class="color-warning"></span>
    <span class="color-warning"></span>
    <span class="color-warning"></span>
    <span class="color-success"></span>
    <span class="color-success"></span>
    <span class="color-success"></span>
    <span class="color-success"></span>
    <span class="color-success"></span>
    <span class="color-success0"></span>
    <span class="color-success"></span>
    <span class="color-success"></span>
    <span class="color-success"></span>
    <span class="color-success"></span>
    <span class="color-fusion"></span>
    <span class="color-fusion"></span>
    <span class="color-fusion"></span>
    <span class="color-fusion"></span>
    <span class="color-fusion"></span>
    <span class="color-fusion0"></span>
    <span class="color-fusion"></span>
    <span class="color-fusion"></span>
    <span class="color-fusion"></span>
    <span class="color-fusion"></span>
  </p>
</template>

<script>
export default {
  mounted() {},
}
</script>
