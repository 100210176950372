<template>
  <div id="headerFormItem">
    <div class="my-6 text-center">
      <h3 class="instructionText">{{ instruction }} </h3>
      <p class="text-md">{{ description }} </p>
      <p class="text-sm italic">{{ required }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderFormItem',
  props: {
    instruction: {
      required: false,
      type: String,
      default: '',
      description: 'Instructions to help user choice'
    },
    description: {
      required: false,
      type: String,
      default: '',
      description: 'Describe the current attribute'
    },
    required: {
      required: false,
      type: String,
      default: ''
    }
  }
}
</script>
