export default {
    setUser(state, data) {
        localStorage.setItem('user', JSON.stringify(data));
        state.user = data;
    },
    setCompany(state, data) {
        localStorage.setItem('company', JSON.stringify(data));
        state.company = data;
    },
    setToken(state, token) {
        localStorage.setItem('token', JSON.stringify(token));
        state.token = token;
    },
};
