<template>
    <div>
        <div v-if="isConnected()">
            <div class="page-wrapper">
                <div class="page-inner">
                    <Menu></Menu>

                    <div class="page-content-wrapper">
                        <TopBar></TopBar>
                        <div v-if="getUserImpersonated">
                            <div class="w-100 text-center p-4 banner">
                                {{ $t('impersonation.impersonated') }}
                                <b>{{ getUserImpersonated.name }}</b>
                                {{ $t('impersonation.company') }}
                                <b>
                                    {{ getUserImpersonated.company.name }}
                                </b>
                                <span
                                    @click="leaveImpersonateUser"
                                    class="btn btn-warning"
                                    >{{ $t('impersonation.leave') }}</span
                                >
                            </div>
                        </div>
                        <fade-transition
                            origin="center"
                            mode="out-in"
                            class="w-full md:p-8 overflow-y-scroll"
                            :duration="250"
                        >
                            <router-view :key="$route.fullPath"></router-view>
                        </fade-transition>

                        <!-- this overlay is activated only when mobile menu is triggered -->
                        <div
                            class="page-content-overlay"
                            data-action="toggle"
                            data-class="mobile-nav-on"
                        ></div>
                        <!--LTU
            <FooterApplication></FooterApplication>
-->
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="page-wrapper">
                <div class="page-inner">
                    <div class="page-content-wrapper bg-brand-gradient">
                        <fade-transition
                            origin="center"
                            mode="out-in"
                            :duration="250"
                        >
                            <router-view></router-view>
                        </fade-transition>
                    </div>
                </div>
            </div>
        </div>
        <b-loading
            v-model="loading"
            :can-cancel="false"
            :is-full-page="true"
        ></b-loading>
    </div>
</template>

<style scoped>
.banner {
    background-color: rgba(117, 100, 100, 0.3);
}
@media (min-width: 991px) and (max-width: 992px) {
    .nav-function-fixed:not(.nav-function-top):not(.nav-function-hidden):not(.nav-function-minify)
        .page-content-wrapper {
        padding-left: 0%;
        width: 100%;
    }
}
@media (min-width: 993px) and (max-width: 1427px) {
    .nav-function-fixed:not(.nav-function-top):not(.nav-function-hidden):not(.nav-function-minify)
        .page-content-wrapper {
        padding-left: 19%;
        width: 81%;
    }
}
</style>

<style>
.ck.ck-content ul,
.ck.ck-content ul li {
    list-style-type: inherit;
}

.ck.ck-content ol,
.ck.ck-content ol li {
    list-style-type: number;
}

.ck.ck-content ul,
.ck.ck-content ol {
    /* Default user agent stylesheet, you can change it to your needs. */
    padding-left: 20px;
}
</style>

<script>
import 'jquery-ui/ui/effects/effect-slide'

//UI Component
import TopBar from '@/components/UI/Menu/TopBar.vue'
import Menu from '@/components/UI/Menu/Menu.vue'
//ltu import FooterApplication from '@/components/UI/FooterApplication.vue'

import ModalShortcut from '@/components/UI/ModalShortcut.vue'
import ColorPickerForJS from '@/components/UI/ColorPickerForJS.vue'
import IconShortcut from '@/components/UI/IconShortcut.vue'
import ModalMessenger from '@/components/UI/ModalMessenger.vue'
import PageSettings from '@/components/UI/PageSettings.vue'

import { FadeTransition } from 'vue2-transitions'

import Login from '@/views/UI/Login.vue'

//Services
import AuthenticationService from '@/services/AuthenticationService'

import { EventManager } from '@/components/EventManager.js'
import { mapActions, mapGetters } from 'vuex'
import { runInThisContext } from 'vm'

export default {
    name: 'App',
    components: {
        TopBar,
        Menu,
        //LTU    FooterApplication,
        ModalShortcut,
        ColorPickerForJS,
        IconShortcut,
        ModalMessenger,
        PageSettings,
        Login,
        EventManager,
        FadeTransition
    },
    data: function () {
        return {
            authenticationService: {},
            loading: false
        }
    },
    computed: {
        ...mapGetters('admin', [
            'getIsUnderImpersonation',
            'getUserImpersonated'
        ])
    },
    methods: {
        ...mapActions('admin', ['leaveImpersonate']),
        leaveImpersonateUser() {
            this.loading = true
            setTimeout(() => {
                this.leaveImpersonate().then(() => {
                    this.$store.dispatch('toast', {
                        type: 'success',
                        title: this.$t('success.title')
                    })
                    this.loading = false
                })
                window.location.reload()
            }, 1000)
        },
        isConnected() {
            var token = localStorage.getItem('token')
            return token && token.length > 0
        }
    }
}
</script>
