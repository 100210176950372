import IconBolt from '@/components/icons/iconBolt'
import IconCadeau from '@/components/icons/iconCadeau'
import IconChart from '@/components/icons/iconChart'
import IconChat from '@/components/icons/iconChat'
import IconCheck from '@/components/icons/iconCheck'
import IconChevronBas from '@/components/icons/iconChevronBas'
import IconChevronHaut from '@/components/icons/iconChevronHaut'
import IconChevronLeft from '@/components/icons/iconChevronLeft'
import IconChevronRight from '@/components/icons/iconChevronRight'
import IconCloud from '@/components/icons/iconCloud'
import IconContact from '@/components/icons/iconContact'
import IconDelete from '@/components/icons/IconDelete'
import IconDownload from '@/components/icons/iconDownload'
import IconFire from '@/components/icons/iconFire'
import IconGlobe from '@/components/icons/iconGlobe'
import IconGroup from '@/components/icons/iconGroup'
import IconHeart from '@/components/icons/iconHeart'
import IconHome from '@/components/icons/iconHome'
import IconHorloge from '@/components/icons/iconHorloge'
import IconHummer from '@/components/icons/iconHummer'
import IconKanban from '@/components/icons/iconKanban'
import IconLabel from '@/components/icons/iconLabel'
import IconList from '@/components/icons/iconList'
import IconMap from '@/components/icons/iconMap'
import IconMobile from '@/components/icons/iconMobile'
import IconModif from '@/components/icons/iconModif'
import IconNotification from '@/components/icons/iconNotification'
import IconPaperClip from '@/components/icons/iconPaperClip'
import IconPhoto from '@/components/icons/iconPhoto'
import IconPlus from '@/components/icons/iconPlus'
import IconProgress from '@/components/icons/iconProgress'
import IconPuzzle from '@/components/icons/iconPuzzle'
import IconReglages from '@/components/icons/iconReglages'
import IconRepeat from '@/components/icons/iconRepeat'
import IconSad from '@/components/icons/iconSad'
import IconSearch from '@/components/icons/iconSearch'
import IconShield from '@/components/icons/iconShield'
import IconSmile from '@/components/icons/iconSmile'
import IconStar from '@/components/icons/iconStar'
import IconTelephone from '@/components/icons/iconTelephone'
import IconTemplate from '@/components/icons/iconTemplate'
import IconTrash from '@/components/icons/iconTrash'
import IconUpload from '@/components/icons/iconUpload'
import IconUser from '@/components/icons/iconUser'
import IconUserPlain from '@/components/icons/iconUserPlain'
import IconWarning from '@/components/icons/iconWarning'
import IconWrite from '@/components/icons/iconWrite'

const Components = {
    install(Vue) {
        Vue.component("IconBolt", IconBolt);
        Vue.component("IconCadeau", IconCadeau);
        Vue.component("IconChart", IconChart);
        Vue.component("IconChat", IconChat);
        Vue.component("IconCheck", IconCheck);
        Vue.component("IconChevronBas", IconChevronBas);
        Vue.component("IconChevronHaut", IconChevronHaut);
        Vue.component("IconChevronLeft", IconChevronLeft);
        Vue.component("IconChevronRight", IconChevronRight);
        Vue.component("IconCloud", IconCloud);
        Vue.component("IconContact", IconContact);
        Vue.component("IconDelete", IconDelete);
        Vue.component("IconDownload", IconDownload);
        Vue.component("IconFire", IconFire);
        Vue.component("IconGlobe", IconGlobe);
        Vue.component("IconGroup", IconGroup);
        Vue.component("IconHeart", IconHeart);
        Vue.component("IconHome", IconHome);
        Vue.component("IconHorloge", IconHorloge);
        Vue.component("IconHummer", IconHummer);
        Vue.component("IconKanban", IconKanban);
        Vue.component("IconLabel", IconLabel);
        Vue.component("IconList", IconList);
        Vue.component("IconMap", IconMap);
        Vue.component("IconMobile", IconMobile);
        Vue.component("IconModif", IconModif);
        Vue.component("IconNotification", IconNotification);
        Vue.component("IconPaperClip", IconPaperClip);
        Vue.component("IconPhoto", IconPhoto);
        Vue.component("IconPlus", IconPlus);
        Vue.component("IconProgress", IconProgress);
        Vue.component("IconPuzzle", IconPuzzle);
        Vue.component("IconReglages", IconReglages);
        Vue.component("IconRepeat", IconRepeat);
        Vue.component("IconSad", IconSad);
        Vue.component("IconSearch", IconSearch);
        Vue.component("IconShield", IconShield);
        Vue.component("IconSmile", IconSmile);
        Vue.component("IconStar", IconStar);
        Vue.component("IconTelephone", IconTelephone);
        Vue.component("IconTemplate", IconTemplate);
        Vue.component("IconTrash", IconTrash);
        Vue.component("IconUpload", IconUpload);
        Vue.component("IconUser", IconUser);
        Vue.component("IconUserPlain", IconUserPlain);
        Vue.component("IconWarning", IconWarning);
        Vue.component("IconWrite", IconWrite);
    }
};

export default Components