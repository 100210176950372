<script>
import { mapActions } from 'vuex'

export default {
    name: 'ToastableMixin',
    methods: {
        ...mapActions('toast', ['fire', 'showValidationError'])
    }
}
</script>
