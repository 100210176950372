export default {
    setConfig(state, config) {
        state.config = config;
    },
    setZoom(state, zoom) {
        state.config.zoom = zoom;
    },
    setPostalAddress(state, postalAddress) {
        state.postalAddress = postalAddress;
    },
    setLocationResult(state, locations) {
        state.locationsResult = locations;
    },
    setCityRestrictConfig(state, city) {
        state.config.cityRestrictConfig = city;
    },
    setPoisConfig(state, poisConfig) {
        state.config.poisConfig = poisConfig;
    },
    setPois(state, pois) {
        state.pois = pois;
    },
    setCities(state, cities) {
        state.cities = cities;
    },
    setLocation(state, location) {
        state.location = location;
    },
    setCenter(state, center) {
        state.config.center = center;
    },

};
