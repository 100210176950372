<template>
    <main
        id="js-page-content"
        role="main"
        class="page-content justify-content-center"
    >
        <div class="col-xl-10 col-lg-12 col-md-9">
            <div class="card o-hidden border-0 shadow-lg my-5">
                <div class="card-body p-0">
                    <div class="row">
                        <div
                            class="col-lg-6 d-none d-lg-block bg-login-image"
                        ></div>
                        <div class="col-lg-6">
                            <div class="p-5">
                                <div class="text-center">
                                    <img
                                        src="@/assets/logo.jpg"
                                        class="img-fluid m-1"
                                    />
                                    <h1 class="h2 text-primary">
                                        {{ $t('auth.welcome') }}
                                    </h1>
                                </div>

                                <form method="POST" class="user mb-4">
                                    <div class="form-group row">
                                        <input
                                            id="email"
                                            placeholder="Email"
                                            type="email"
                                            class="form-control form-control-user"
                                            name="email"
                                            v-model="email"
                                            required
                                            autocomplete="email"
                                            autofocus
                                        />
                                    </div>
                                    <div class="form-group row">
                                        <input
                                            placeholder="Mot de passe"
                                            v-model="password"
                                            id="password"
                                            type="password"
                                            class="form-control form-control-user"
                                            name="password"
                                            required
                                            autocomplete="current-password"
                                        />
                                    </div>

                                    <button
                                        id="loginBtn"
                                        class="btn btn-primary btn-user btn-block"
                                        @click.prevent="connection"
                                        v-show="!loading"
                                    >
                                        {{ $t('auth.login') }}
                                    </button>

                                    <button
                                        class="btn btn-primary btn-user btn-block waves-effect waves-themed"
                                        type="button"
                                        disabled="disabled"
                                        v-show="loading"
                                    >
                                        <span
                                            class="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                        {{ $t('auth.login') }}...
                                    </button>
                                </form>

                                <hr />

                                <div class="text-center">
                                    <a class="btn btn-link" href="#">
                                        {{ $t('auth.forgot_password') }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Toaster ref="toaster" />
    </main>
</template>

<style scoped>
.h2 {
    margin: 0.5em 0 1em 0;
}
</style>

<script>
import Toaster from '@/components/UI/Helper/Toaster'
export default {
    name: 'Login',
    props: ['authenticationService'],
    components: {
        Toaster
    },
    data: function () {
        return {
            email: '',
            password: '',
            loading: false,
            backendUrl: process.env.VUE_APP_BACKEND_URL
        }
    },
    watch: {},
    created() {},
    methods: {
        connection: function () {
            if (this.email.length > 0 && this.password.length > 0) {
                this.loading = true
                this.authenticationService.login(
                    {
                        email: this.email,
                        password: this.password
                    },
                    (response) => {
                        this.authenticationService.me(
                            (response) => {
                                this.$store.commit('setUser', response.data)
                                this.$emit('connected')
                                this.$router.push('/')
                                this.loading = false
                            },
                            (response) => {
                                this.loading = false

                                this.$refs.toaster.fire(
                                    'error',
                                    response.response.data.error_description,
                                    response.error
                                )
                            }
                        )
                    },
                    (response) => {
                        this.$refs.toaster.fire(
                            'error',
                            response.response.data.error_description,
                            response.error
                        )
                        this.loading = false
                    }
                )
            }
        }
    },
    mounted() {}
}
</script>
