import axios from "axios";
axios.defaults.headers.common['Accept'] = 'application/json'

import {
    latLng
} from "leaflet";
import LocationService from "@/services/LocationService";

export default {
    /*
    |--------------------------------------------------------------------------
    | Handle Location's changes
    |--------------------------------------------------------------------------
    */
    //triggered by "Find me" button
    setUserLocation({ state, commit }) {
        return new Promise((resolve, reject) => {
            if (!('geolocation' in navigator)) {
                console.log('Geolocation is not available.')
                return
            }
            navigator.geolocation.getCurrentPosition(
                (pos) => {
                    var config = state.config

                    var lat = pos.coords.latitude
                    var lon = pos.coords.longitude

                    config.center = [lat, lon]

                    commit('setConfig', config)
                    commit('setLocation', latLng(lat, lon))

                    resolve(latLng(lat, lon))
                },
                (err) => {
                    reject({ err: err, msg: 'Geolocation is not available.' })
                }
            )
        })
    },

    //triggered by a click on the map
    setLocation: function ({ commit }, coords) {
        return new Promise((resolve, reject) => {
            commit('setLocation', coords)
            resolve(coords)
        })
    },

    //triggered by new postal address entered by the user
    searchPlacesFromPostalAddress({ state, commit }, searchAddress) {
        return new Promise((resolve, reject) => {
            state.resolver
                .search(searchAddress)
                .then((results) => {
                    commit('setLocationResult', results)
                    resolve(results)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    //triggered by new postal address entered by the user
    searchPostalLocation({ state, commit }, coords) {
        return new Promise((resolve, reject) => {
            state.resolver
                .reverseSearch({
                    lat: coords.lat,
                    lon: coords.lng
                })
                .then((result) => {
                    commit('setPostalAddress', result)
                    resolve(result)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    reset(context) {
        context.commit('setConfig', {
            type: null,
            zoom: 11,
            center: null,
            poisConfig: null,
            cityRestrictConfig: null
        })

        context.commit('setLocationResult', null)
        context.commit('setLocation', null)
    },

    /*
    |--------------------------------------------------------------------------
    | Set up the map
    |--------------------------------------------------------------------------
    */
    initMap({ state, rootState, commit }, config) {
        return new Promise((resolve, reject) => {
            commit('setConfig', config.config)
            commit('setCityRestrictConfig', config.restrict)
            commit('setPoisConfig', config.pois)

            let mapConfig = {
                mapProvider: config.config.type
            }

            if (config.config.restriction) {
                mapConfig.restriction = {}
                if (config.config.restriction.city) {
                    mapConfig.restriction.city = config.config.restriction.city
                }
                if (config.config.restriction.country) {
                    mapConfig.restriction.country =
                        config.config.restriction.country
                }
            }
            state.resolver = new LocationService(
                mapConfig,
                rootState.auth.company.id
            )

            resolve()
        })
    },

    getPOI({ state, commit }) {
        return new Promise((resolve, reject) => {
            try {
                var tags = state.config.poisConfig
                var pois = []

                for (var i = 0; i < tags.length; i++) {
                    var geometry = {
                        id: tags[i].id,
                        icon: tags[i].icon,
                        name: tags[i].name,
                        description: tags[i].description,
                        visible: true
                    }

                    //is it point or polygon ?
                    var coordinates = tags[i].coordinates.split(';')
                    if (coordinates.length > 1) {
                        var tabResultat = []
                        for (var j = 0; j < coordinates.length; j++) {
                            var latLngTab = coordinates[j].split(',')
                            tabResultat.push([latLngTab[1], latLngTab[0]])
                        }

                        geometry.type = 'polygon-poi'
                        geometry.coordinates = tabResultat
                    } else {
                        var latLngTab = tags[i].coordinates.split(',')

                        geometry.type = 'point-poi'
                        geometry.coordinates = [latLngTab[0], latLngTab[1]]
                    }

                    pois.push(geometry)
                }

                commit('setPois', pois)
                resolve()
            } catch (error) {
                reject(error)
            }
        })
    },
    initDelimiterZone({ state, commit }) {
        return new Promise((resolve, reject) => {
            try {
                var map = state.config.cityRestrictConfig

                if (map.cities.length > 0) {
                    var citiesPolygone = ''
                    var maxIndex = map.cities.length - 1
                    map.cities.forEach(function (valueCity, key) {
                        if (valueCity.city && valueCity.city.length > 0) {
                            var city = valueCity.city

                            if (key < maxIndex) {
                                citiesPolygone = citiesPolygone.concat(
                                    '[[' + city + ']]',
                                    ','
                                )
                            } else {
                                citiesPolygone = citiesPolygone.concat(
                                    '[[' + city + ']]'
                                )
                            }
                        }
                    })

                    var cities = '[' + citiesPolygone + ']'
                    cities = JSON.parse(cities)
                    var states = [
                        {
                            type: 'Feature',
                            geometry: {
                                type: 'MultiPolygon',
                                coordinates: cities
                            }
                        }
                    ]

                    commit('setCities', states)
                    resolve()
                }
            } catch (error) {
                reject(error)
            }
        })
    }
}
