export default () => ({
    forms: null,
    form: null,
    types: null,
    formsConfig: {
        include: "",
        sort: 'id',
        sortDirection: 'desc',
        page: 1,
        filters: {}
    },

    newForm: {
        fr: {
            name: null,
            description: null,
        },
        en: {
            name: null,
            description: null,
        },
        form_type_id: null,
        racis:[],
        // racis: {
        //     responsible: [],
        //     accountable: [],
        //     consulted: [],
        //     informed: [],
        // },
        steps: null
    }

    ,

    defaultForm: {
        fr: {
            name: null,
            description: null,
        },
        en: {
            name: null,
            description: null,
        },
        form_type_id: null,
        racis:[],
        // racis: {
        //     responsible: [],
        //     accountable: [],
        //     consulted: [],
        //     informed: [],
        // },
        steps: null
    }
});
