export default {
    setFormId(state, id) {
        state.formId = id
    },
    setApplications(state, data) {
        state.applications = data
    },
    setApplication(state, data) {
        state.application = data
    }
}
