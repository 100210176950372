import axios from 'axios'
axios.defaults.headers.common['Accept'] = 'application/json'

export default {
    login({ state, rootState, commit, dispatch }, user) {
        return new Promise((resolve, reject) => {
            try {
                axios
                    .post(`${process.env.VUE_APP_BACKEND_URL}/api/tokens`, user)
                    .then((response) => {
                        if (
                            !response.data.token ||
                            response.data.token.length <= 0
                        ) {
                            reject('Token not found')
                        }

                        var token = response.data.token.split('|')[1]

                        commit('setToken', token)
                        commit('setUser', response.data.user)
                        commit('setCompany', response.data.user.company)

                        window.localStorage.setItem('token', token)

                        dispatch('initialiseStore', null, { root: true })

                        resolve()
                    })
                    .catch((error) => {
                        reject(error)
                    })
            } catch (error) {
                reject(error)
            }
        })
    },
    forgotPassword({ rootState, state }, user) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${process.env.VUE_APP_BACKEND_URL}/api/forgot-password`,
                    user
                )
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    resetPassword({ rootState, state }, user) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${process.env.VUE_APP_BACKEND_URL}/api/reset-password`,
                    user
                )
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    logout({ rootState }) {
        return new Promise((resolve, reject) => {
            try {
                axios
                    .get(`${process.env.VUE_APP_BACKEND_URL}/api/logout`)
                    .then((response) => {
                        localStorage.clear()

                        resolve()
                    })
                    .catch((error) => {
                        reject(error)
                    })
            } catch (error) {
                reject(error)
            }
        })
    },

    searchUser({ rootState }, search) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${rootState.baseURL}/users/find`, { params: { search } })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    updateLocale({ rootState, state }, locale) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${rootState.baseURL}/users/${state.user.id}/locale`, {
                    locale
                })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}
