import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import * as Toastr from 'toastr'
import i18n from '../i18n/i18n'
import moment from 'moment'
Vue.use(Vuex)
import * as Sentry from '@sentry/browser'

export default new Vuex.Store({
    state: {
        lang: null,
        langs: ['fr', 'en'],
        baseURL: null,
        toaster: {
            closeButton: false,
            debug: false,
            newestOnTop: true,
            progressBar: true,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            onclick: null,
            showDuration: 300,
            hideDuration: 100,
            timeOut: 3500,
            extendedTimeOut: 1000,
            showEasing: 'swing',
            hideEasing: 'linear',
            showMethod: 'fadeIn',
            hideMethod: 'fadeOut'
        }
    },
    mutations: {
        setLang(state, lang) {
            localStorage.setItem('lang', JSON.stringify(lang))
            i18n.locale = lang
            state.lang = lang
            moment.locale(lang)
        }
    },
    actions: {
        toast: (state, data) => {
            Toastr.options = state.toaster
            Toastr[data.type](data.message, data.title)
        },
        dispatchError: ({ dispatch }, error) => {
            let traduction = i18n.messages[i18n.locale]
            let title = traduction.error.title
            let message = traduction.error.default_message
            console.log(error.response.status)
            if (error.response && error.response.status) {
                switch (error.response.status) {
                    case 400:
                    case 401:
                    case 403:
                        title =
                            error.response.data.message === 'app_not_found'
                                ? traduction.error.app.not_found.title
                                : error.response.data.message
                                ? error.response.data.message
                                : error['title']
                                ? error['title']
                                : traduction.error.title
                        message =
                            error.response.data.message === 'app_not_found'
                                ? traduction.error.app.not_found.message
                                : error.response.data.error ===
                                  'Wrong username or password'
                                ? error['message']
                                : ''
                        break
                    case 404:
                        title = error.response.data.message
                            ? error['title']
                            : error.response.data.message
                            ? error.response.data.error
                            : traduction.error.title
                        break
                    case 422:
                        title = traduction.error.unprocessable.title
                        message = `${traduction.error.unprocessable.message} ${message}`
                        Sentry.withScope((scope) => {
                            scope.setExtra('code', '422');
                            Sentry.captureException(error);
                        });
                        break
                    case 429:
                        message = traduction.error.validation['too_many']
                        title = traduction.error.validation['auto_protection']
                        break
                    case 500:
                        title = `${traduction.error.server.title} ${error.response.status}`
                        message = `${traduction.error.server.message} ${message}`
                        Sentry.withScope((scope) => {
                            scope.setExtra('code', '500');
                            Sentry.captureException(error);
                        });
                        break
                    case error.response.status > 500:
                        title = `${traduction.error.server.title} ${error.response.status}`
                        message = `${traduction.error.server.message} ${message}`
                        Sentry.withScope((scope) => {
                            scope.setExtra('code', error.response.status);
                            Sentry.captureException(error);
                        });
                        break
                }
            }
            dispatch('toast', {
                type: 'error',
                title: title,
                message: message
            })
        },
        initialiseStore({ state, commit }) {
            var user = localStorage.getItem('user')
            var company = localStorage.getItem('company')
            var token = localStorage.getItem('token')
            var lang = localStorage.getItem('lang')

            if (!user || !company || !token) {
                localStorage.clear()
                return
            }

            state.auth.token = token
            state.auth.user = JSON.parse(user)
            state.auth.company = JSON.parse(company)

            commit('setLang', lang ? JSON.parse(lang) : state.auth.user.locale)

            var baseURL = `${process.env.VUE_APP_BACKEND_URL}/api/${state.auth.company.id}`

            state.baseURL = baseURL
            localStorage.setItem('baseURL', baseURL)
        },
        prepareParams(context, config) {
            var field = config.sort

            if (config.sortDirection === 'desc') {
                field = '-' + field
            }

            var params = {
                page: config.page,
                include: config.include,
                sort: field
            }

            if (config.filters) {
                for (const [key, value] of Object.entries(config.filters)) {
                    params[`filter[${key}]`] = value
                }
            }

            return params
        },
        scrollTo(context, div) {
            document.getElementById(div).scrollIntoView()
        }
    },
    modules: modules
})
