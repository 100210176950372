import { render, staticRenderFns } from "./HeaderFormItem.vue?vue&type=template&id=44f36aa8&"
import script from "./HeaderFormItem.vue?vue&type=script&lang=js&"
export * from "./HeaderFormItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../BougeMaVille-reborn/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports