<template>
  <div id="category">
    <div class="flex items-center justify-center">
      <i
          :class="
                    attribute.response_private === 1 ? 'fas fa-lock pr-2' : ' '
                "
      ></i>
      <HeaderFormItem
          :help="attribute.help"
          :instruction="`${
                    attribute.instruction ? attribute.instruction : ''
                } ${requiredasterisk}`"
          :description="`${
                    attribute.description ? attribute.description : ''
                } `"
          :required="`${requiredmessage} `"
      >
      </HeaderFormItem>
    </div>

    <div class="flex justify-around flex-wrap items-center">
      <div
          v-for="(option, index) in options"
          :key="option.label"
          class="text-center cursor-pointer my-2"
          @click.prevent="setSelected(index)"
      >
        <button
            :class="{
                        'border-4 border-themeColor rounded-md':
                            result && result.value && result.value.label === option.label,
                             'border-4 border-transparent rounded-md':
                            result && result.value && result.value.label !== option.label
                    }"
            class="figure"
        >
          <figure class="is-inline-block mb-2">
            <i v-if="option.icon" :class="option.icon" class="icon"></i>
            <i v-else class="fa-solid fa-folder icon"></i>
          </figure>

          <div
              v-if="!option.label.includes(' ')"
              class="content md:text-base text-sm"
          >
            {{ option.label }}
          </div>
          <div v-else class="content">
            <div
                v-for="(label, index) in option.label.split(' ')"
                :key="index"
                class="md:text-base text-sm"
            >
              {{ label }}
            </div>
          </div>
        </button>
      </div>
    </div>
    <b-field :message="message" type="is-danger font-bold text-center">
    </b-field>
  </div>
</template>


<script>
import FormItemMixin from '../FormItemMixin'
import HeaderFormItem from '../HeaderFormItem'

export default {
  name: 'CategoryFormItem',
  components: {
    HeaderFormItem
  },
  mixins: [FormItemMixin],
  data() {
    return {
      options: []
    }
  },
  created() {
    this.getOptions()
  },
  methods: {
    getOptions() {
      this.options = this.attribute.config.options[this.$i18n.locale]
    },
    setSelected(index) {
      this.doEmit({
        value: this.options[index],
        callback: (result) => {
          this.result = result
        }
      })
    }
  }
}
</script>

<style scoped>
#category .field label {
text-align: center;
}
#category  .icon {
width:100%;
height:100%;
}
#category .figure.border-transparent {
border: 4px solid transparent !important;
}
@media (min-width: 768px) {
#category  .icon {
font-size: 7em;
}
#category .figure {
padding : 2em !important;
}
}

@media (max-width: 767px) {
#category .icon  {
font-size: 4em;
}
#category .figure {
padding : 1.5em 1em !important;
}
}
</style>
