export default () => ({
    applications: null,
    application: {
        name: '',
        source: '',
        client_url: '',
        active: 1,
        lang: 'fr'
    },
    applicationsConfig: {
        include: 'createdBy',
        sort: 'created_at',
        sortDirection: 'desc',
        page: 1,
        filters: '',
        per_page: 10
    },
    formId: null
})
