// VUE and dependancies
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n/i18n.js'

// PWA
import './registerServiceWorker'

// Others
import axios from './config/backend'
import Components from './config/components'
import VueAxios from 'vue-axios'

import PortalVue from 'portal-vue'
import moment from 'moment'
import VueSweetalert2 from 'vue-sweetalert2'
import Buefy from 'buefy'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import vSelect from 'vue-select'
import VueClipboard from 'vue-clipboard2'

import 'jquery-ui/ui/effects/effect-slide'

import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'

import {
    Chart,
    Title,
    BarController,
    PieController,
    BarElement,
    PointElement,
    LinearScale,
    CategoryScale,
    ArcElement,
    Legend
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
Chart.register(
    Title,
    BarController,
    PieController,
    BarElement,
    PointElement,
    LinearScale,
    CategoryScale,
    ArcElement,
    ChartDataLabels,
    Legend
)

Vue.prototype.moment = moment

Vue.config.productionTip = true
Vue.use(Components)
Vue.use(VueAxios, axios)
Vue.use(VueSweetalert2)
Vue.use(CKEditor)
Vue.use(Buefy, {
    defaultIconPack: 'fas'
})
Vue.use(PortalVue)
Vue.component('v-select', vSelect)
Vue.use(VueClipboard)

//import package form
import WizardPackage from 'wizard-package'
Vue.use(WizardPackage)

// css
import 'leaflet/dist/leaflet.css'
import '~theme/scss/_map.scss'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import '~theme/scss/app.core.scss'
import '~theme/scss/_themes/cust-theme-3.scss'

import 'sweetalert2/dist/sweetalert2.min.css'
import 'toastr/build/toastr.css'
import 'vue-select/dist/vue-select.css'

import 'buefy/dist/buefy.css'
import 'toastr/build/toastr.css'

// import css du projet
import '@/assets/css/bmv/main.css'
/* css wizard */
import '@/assets/css/bmv/components/wizard.scss'

require('jquery-slimscroll')
require('node-waves')

window.Popper = require('popper.js').default

require('~theme/js/_config/app.config.js')
require('~theme/js/_modules/app.init.js')
require('~theme/js/_modules/app.navigation.js')
require('~theme/js/_modules/app.menu.slider.js')
require('~theme/js/_modules/app.layout.shortcuts.js')
require('~theme/js/_modules/app.resize.trigger.js')
require('~theme/js/_modules/app.scroll.trigger.js')
require('~theme/js/_modules/app.domReady.js')
require('~theme/js/_modules/app.orientationchange.js')
require('~theme/js/_modules/app.window.load.js')
require('~theme/js/_config/app.colors.js')
require('~theme/plugins/jquery-snippets/jquery-snippets.js')
require('~theme/plugins/jquery-ui-cust/jquery-ui-cust.js')
//LTU require('~theme/plugins/jquery-sparkline/jquery-sparkline.config.js');
require('~theme/plugins/smartpanels/smartpanels.js')

Vue.filter('truncate', function (value, limit) {
    if (value.length > limit) {
        value = value.substring(0, limit - 3) + '...'
    }

    return value
}),
    Vue.filter('capitalize', function (value) {
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
    })

import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

Vue.component('v-marker-cluster', Vue2LeafletMarkerCluster)

//Sentry
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ['localhost', process.env.VUE_APP_PROD, /^\//]
        })
    ],
    tracesSampleRate: 1.0
})

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

if (process.env.VUE_APP_THEME === 'dark') {
    document.getElementsByTagName('body')[0].classList.add('mod-skin-dark')
}

new Vue({
    router,
    store,
    beforeCreate() {
        this.$store.dispatch('initialiseStore', router)
    },
    i18n,
    render: (h) => h(App)
}).$mount('#app')
