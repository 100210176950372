import axios from '@/config/backend'

const TOKEN_KEY = 'token_impersonate'
const USER_IMPERSONATED = 'user_impersonated'
export default class ImpersonateService {
    constructor() {
        this.myTokenStorage = localStorage
    }
    underImpersonation() {
        return this.myTokenStorage.getItem(TOKEN_KEY, '') != null
    }
    userImpersonated() {
        return JSON.parse(this.myTokenStorage.getItem(USER_IMPERSONATED))
    }
    setToken(data) {
        this.myTokenStorage.setItem(TOKEN_KEY, data.token)
        this.myTokenStorage.setItem(
            USER_IMPERSONATED,
            JSON.stringify(data.user)
        )
    }
    removeToken() {
        this.myTokenStorage.removeItem(TOKEN_KEY)
        this.myTokenStorage.removeItem(USER_IMPERSONATED)
    }
    async impersonateUser(userId) {
        return new Promise(async (resolve, reject) => {
            await axios
                .get(`/api/impersonate-user/${userId}`)
                .then(({ data }) => {
                    this.setToken(data)
                    resolve(data)
                })
                .catch((e) => reject(e))
        })
    }
    async leaveImpersonate() {
        return new Promise((resolve, reject) => {
            this.removeToken()
            resolve({
                user: null,
                token: null
            })
        })
    }
}
