<template>
  <div>
    <HeaderFormItem
        :instruction="`${
                attribute.instruction ? attribute.instruction : ''
            } ${requiredasterisk}`"
        :description="`${
                attribute.description ? attribute.description : ''
            } `"
        :required="`${requiredmessage} `"
    >
    </HeaderFormItem>
    <div class="flex items-center w-full">
      <i
          :class="
                    attribute.response_private == 1
                        ? 'fas fa-lock px-1 pt-2'
                        : ' '
                "
      ></i>
      <b-field
          :label="`${attribute.label ? attribute.label : ''} ${asterisk}`"
          :message="attribute.help ? attribute.help : ''"
          class="w-full"
      >
        <ckeditor
            class="markdownInput"
            v-model="result"
            :config="editorConfig"
            :editor="editor"
        ></ckeditor>
      </b-field>
    </div>
    <b-field :message="message" type="is-danger font-bold text-center">
    </b-field>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import FormItemMixin from '../FormItemMixin'
import HeaderFormItem from '../HeaderFormItem'

export default {
  name: 'MarkdownFormItem',
  mixins: [FormItemMixin],
  components: {
    HeaderFormItem
  },
  watch: {
    result: function (newValue) {
      this.doSave({
        id: this.attribute.id,
        value: newValue,
        type_id: this.attribute.form_attribute_type_id
      })
    }
  },
  created() {
    if (this.shouldInitialize()) {
      this.result =
          this.$store.state.post.post.attributes[this.attribute.id].value
      this.saveUserAnswer({
        id: this.attribute.id,
        type_id: this.attribute.form_attribute_type_id,
        value: this.$store.state.post.post.attributes[this.attribute.id]
            .value
      })
    }
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            'bold',
            'italic',
            'link',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'undo',
            'redo'
          ]
        }
      }
    }
  }
  }
</script>