import axios from '@/config/backend'

export default class Bing {

    constructor(key, restrictedCountry, restrictedCity, company) {
        this.restrictedCountry = restrictedCountry;
        this.restrictedCity = restrictedCity;
        this.company = company;
        this.errors = []
    }

    search(locationName) {
        return axios.get(`/api/${this.company}/location/${locationName}`);
    }

    searchCity(locationName) {
        return axios.get(`/api/${this.company}/location/${locationName}/restricted/${this.restrictedCity}`);
    }

    handle(response) {
        if (!response.resourceSets || !response.resourceSets[0])
            this.errors.push('error.search.globalError')

        if (response.resourceSets[0].estimatedTotal <= 0) {
            this.errors.push('error.search.noData')
        }

        var locationsResult = [];
        var noDuplicateData = [];
        var data = response.resourceSets[0].resources;

        data.forEach(element => {
            var formattedAddress = element.address.formattedAddress;

            if (!noDuplicateData.includes(formattedAddress)) {
                if (this.checkCountryRestrictionFilter(element.address)) {
                    locationsResult.push({
                        'address': formattedAddress,
                        'lat': element.geocodePoints[0].coordinates[0],
                        'lon': element.geocodePoints[0].coordinates[1],
                    })

                    noDuplicateData.push(formattedAddress);
                }
            }
        });

        return locationsResult;
    }

    reverseSearch(coordinates) {
        return axios.get(`/api/${this.company}/location/coordinates/${coordinates.lat}/${coordinates.lon}`);
    }

    reverseHandle({response}) {
        if (!response.resourceSets || !response.resourceSets[0])
            this.errors.push('error.search.globalError')

        if (response.resourceSets[0].estimatedTotal <= 0)
            this.errors.push('error.search.noData')

        var data = response.resourceSets[0].resources[0];

        if (data.address.formattedAddress)
            return data.address.formattedAddress;
    }

    checkCountryRestrictionFilter(address) {
        if (!this.restrictedCountry || this.restrictedCountry.length <= 0)
            return true;

        if (!address.countryRegion)
            return true;

        return address.countryRegion.toLowerCase() === this.restrictedCountry.toLowerCase()
    }

}
