<template>
  <div>
    <button
      type="button"
      data-toggle="dropdown"
      aria-expanded="false"
      class="my-4 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2"
      :trigger="showFilterView()"
    >
      Export
      <icon-chevron-bas class="h-5 w-5" />
    </button>
    <ul class="dropdown-menu">
      <li>
        <a
          href="#"
          :download="this.fileAllPosts.name"
          class="dropdown-item"
          style="padding-right: 16px"
          @click.prevent="getAllPosts()"
        >
        {{ $t('post.page.export.all') }}    
        </a>
        <a
          v-if="isVisible"
          href="#"
          class="dropdown-item"
          style="padding-right: 16px"
          @click.prevent="getFilterPosts()"
        >
        {{ $t('post.page.export.filtered') }}    
        </a>
      </li>
    </ul>
  </div>
</template>
<style scoped>
.dropdown-menu {
  left: 1415px;
}
</style>
<script>
import { mapActions } from 'vuex'
import iconChevronBas from '@/components/icons/iconChevronBas.vue'
export default {
  name: 'Export',
  components: { iconChevronBas },
  data: function () {
    return {
      isVisible: false,
      fileAllPosts: { content: '', name: '' },
      filter: {},
    }
  },
  methods: {
    ...mapActions('post', ['getExportPosts']),
    getAllPosts() {
      this.filter.filters = {}
      this.getPosts(this.filter)
    },
    getFilterPosts() {
      this.getPosts()
    },
    getPosts(filter) {
      this.getExportPosts(filter).then((response) => {
        this.toFile(response.data.content, response.data.name)
      })
    },
    toFile(text, name) {
      var a = document.createElement('a')
      a.href =
        'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
        text
      a.download = name
      a.click()
    },
    showFilterView() {
      if (this.$route.path == '/posts') {
        this.isVisible = true
      } else {
        this.isVisible = false
      }
    },
  },
}
</script>
