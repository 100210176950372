import axios from 'axios'

export default class OSM {

    constructor() {
        this.errors = [];
        this.domain = 'https://nominatim.openstreetmap.org'
    }

    search(locationName){
        return axios.get(this.domain+'/search', {
            params: {
              q: locationName,
              format: 'json',
              addressdetails:1,
              countrycodes : 'fr,be,ch',
              'accept-language': 'fr-FR'
            }
        });
    }
    
    handle(response){      
        if(!response || response.length <= 0)
            this.errors.push('error.search.noData')
        
        var locationsResult = [];
        var noDuplicateData = [];

        response.forEach(element => {
            var formattedAddress = this.getFormattedAddress(element.address);

            if(formattedAddress){
                if(!noDuplicateData.includes(formattedAddress)){
                    locationsResult.push({
                        'address':formattedAddress,
                        'lat':element.lat,
                        'lon':element.lon,                    
                    })

                    noDuplicateData.push(formattedAddress);
                }
            }
        });

        return locationsResult;
    }

    reverseSearch(coordinates){
        return axios.get(this.domain+'/reverse', {
            params: {
                lat: coordinates.lat,
                lon: coordinates.lon,
                format: 'json',
                countrycodes : 'fr,be,ch',
                'accept-language': 'fr-FR'
            }
        })
    }
    
    reverseHandle(response){
        if(!response || !response.address)
            this.errors.push('error.search.noData')

        return this.getFormattedAddress(response.address)
    }

    getFormattedAddress(address){
        var addr = '';

        if (address.house_number)
            addr += `${address.house_number} `        

        if (address.road)
            addr += `${address.road}, `

        if (address.country == "Belgique") {
            if(address.postcode)
                addr += `${address.postcode} `
            
            addr += address.city || address.town || address.village || address.municipality || address.state
        } else {
            if(address.postcode)
            addr += `${address.postcode} `

            addr += `${address.village || address.town || address.city || address.municipality}`
        }
        
        return addr;
    }

}


