import axios from 'axios'
axios.defaults.headers.common['Accept'] = 'application/json'

/**
 * Forms don't need pagination, they should always be less than 10
 * @param context
 * @returns {Promise<unknown>}
 */
export default {
    /*
    |--------------------------------------------------------------------------
    | GET form
    |--------------------------------------------------------------------------
    |
    */
    async getForms({ rootState, commit, dispatch, state }) {
        let params = await dispatch('prepareParams', state.formsConfig, {
            root: true
        })

        return new Promise((resolve, reject) => {
            axios
                .get(`${rootState.baseURL}/forms`, { params })
                .then((response) => {
                    commit('setForms', response.data.forms)
                    commit('setFormsTypes', response.data.types)

                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getForm({ rootState, state, commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${rootState.baseURL}/forms/${id}`)
                .then((response) => {
                    state.form = null
                    commit('setForm', response.data.form)
                    resolve(response.data.form)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getPublicForm({ rootState, state, commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${rootState.baseURL}/forms/${id}/public`)
                .then((response) => {
                    commit('setForm', response.data.form)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    initForm({ state, commit }) {
        commit('setNewForm', state.defaultForm)
    },
    editForm({ rootState, state, commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${rootState.baseURL}/forms/${id}`)
                .then((response) => {
                    let form = response.data.form
                    if (form) {
                        for (let i = 0; i < rootState.langs.length; i++) {
                            let lang = rootState.langs[i]
                            form[lang] = form.translations.find(
                                (translation) => translation.locale === lang
                            )
                        }

                        delete form.translations
                    }
                    commit('setNewForm', response.data.form)

                    resolve(response.data.form)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    /*
    |--------------------------------------------------------------------------
    | POST/PUT form
    |--------------------------------------------------------------------------
    |
    */
    createForm({ rootState, state, commit }) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${rootState.baseURL}/forms`, state.newForm)
                .then((response) => {
                    commit('setForms', response.data.form)
                    commit('setFormsTypes', response.data.types)

                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    updateForm({ rootState, state, commit }) {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    `${rootState.baseURL}/forms/${state.newForm.id}`,
                    state.newForm
                )
                .then((response) => {
                    commit('setForms', response.data.form)
                    commit('setFormsTypes', response.data.types)
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    /*
    |--------------------------------------------------------------------------
    | DEL form
    |--------------------------------------------------------------------------
    |
    */
    deleteForm({ rootState, state, commit }, data) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${rootState.baseURL}/forms/${data.id}`, state.newForm)
                .then((response) => {
                    let forms = state.forms
                    forms.splice(data.index, 1)

                    commit('setForms', forms)

                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}
