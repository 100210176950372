import { render, staticRenderFns } from "./PushNotifPopUp.vue?vue&type=template&id=29b68327&scoped=true&"
import script from "./PushNotifPopUp.vue?vue&type=script&lang=js&"
export * from "./PushNotifPopUp.vue?vue&type=script&lang=js&"
import style0 from "./PushNotifPopUp.vue?vue&type=style&index=0&id=29b68327&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../BougeMaVille-reborn/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29b68327",
  null
  
)

export default component.exports