<template>
  <section id="popUp" :class="{ none: isPopUpInActive }">
    <i
        id="fermeturePopup"
        @click.prevent="closePopUp()"
        class="fas fa-times-circle"
    ></i>
    <p class="text-md bold m-2">
      {{ $t('post.page.notifications.subscription') }}
    </p>
    <div class="text-xl text-white">
      <button
          @click.prevent="activePushNotif()"
          class="bg-themeColor px-4 py-1 mr-2 rounded-full hover-bg-secondaryColor"
      >
        {{ $t('yes') }}
      </button>
      <button
          @click.prevent="closePopUp()"
          class="bg-themeColor px-4 py-1 mr-2 rounded-full hover-bg-secondaryColor"
      >
        {{ $t('no') }}
      </button>
    </div>
  </section>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'PushNotifPopUp',
  data() {
    return {
      isPopUpInActive: true
    }
  },
  created() {
    if (!this.isPushNotifActived) {
      this.isPopUpInActive = false
    }
  },
  computed: {
    isPushNotifActived: {
      get() {
        return this.$store.state.auth.isPushNotifActived
      },
      set(value) {
        this.$store.commit('auth/setIsPushNotifActived', value)
      }
    }
  },
  methods: {
    ...mapActions('auth', ['sendIsPushNotif']),

    activePushNotif() {
      this.isPushNotifActived = true
      this.pushNotif = {value: true}
      this.sendIsPushNotif(this.pushNotif)
          .catch((e) => {
            this.$store.dispatch('toastError', e)
          })
          .finally(() => {
            this.isPopUpInActive = true
          })
    },
    closePopUp() {
      this.isPopUpInActive = true
    }
  }
}
</script>

<style scoped>
#fermeturePopup {
  font-size: 1.3em;
  display: inline;
  float: right;
}

@media screen and (max-width: 767px) {
  #popUp {
    max-width: 80%;
    right: 10%;
    left: 10%;
  }
}

@media (min-width: 768px) and (max-width: 1250px) {
  #popUp {
    max-width: 40%;
    right: 15%;
    left: 45%;
  }
}

@media (min-width: 1250px) {
  #popUp {
    max-width: 40%;
    right: 25%;
    left: 35%;
  }
}

#popUp {
  position: fixed;
  top: 25%;
  z-index: 9;
  background-color: white;
  padding: 2%;
  border-radius: 2em;
  font-size: 1em;
  box-shadow: 0px 5px 10px black;
  text-align: center;
}

.none {
  display: none;
}
</style>
