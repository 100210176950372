import axios from 'axios'
import router from '../router'

/*
|--------------------------------------------------------------------------
| Axios Base config
|--------------------------------------------------------------------------
|
*/
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL

/*
|--------------------------------------------------------------------------
| Axios Request Interceptor
|--------------------------------------------------------------------------
|
*/
axios.interceptors.request.use(
    (config) => {
        config.headers['Accept'] = 'application/json'
        const tokenImpersonate = localStorage.getItem('token_impersonate')
        let token = localStorage.getItem('token')
        token = tokenImpersonate ?? token
        if (token) config.headers['Authorization'] = `Bearer ${token}`

        return config
    },

    (error) => {
        return Promise.reject(error)
    }
)

/*
|--------------------------------------------------------------------------
| Axios Response Interceptor
|--------------------------------------------------------------------------
|
*/
axios.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        if (error.response.status && error.response.status === 401) {
            localStorage.removeItem('token')
            router.push('/')
        }
        if (error.response.status && error.response.status === 404) {
            router.push('/404')
        }
        return Promise.reject(error)
    }
)

export default axios
