import ImpersonateService from '@/services/Impersonate/ImpersonateService'

export default () => ({
    companiesConfig: {
        include: 'form,status,company, created_by',
        sort: 'id',
        sortDirection: '',
        page: 1,
        filters: '',
        per_page: 15
    },
    companies: null,
    company: null,
    statuses: null,
    companiesFilters: null,
    newCompany: {
        attributes: {}
    },
    isUnderImpersonation: new ImpersonateService().underImpersonation(),
    userImpersonated: new ImpersonateService().userImpersonated(),
    templates: [],
    templatesConfig: {
        sort: 'id',
        sortDirection: 'desc',
        page: 1,
        filters: {},
        per_page: 15
    },
    templatesFilters: null,
    template: null,
    pagination: null
})
