import Vue from 'vue'
import VueRouter from 'vue-router'

import postRoutes from './post'
import formRoutes from './form'
import adminRoutes from './admin'
import companyRoutes from './company'

import PageNotFound from '@/views/UI/PageNotFound'

Vue.use(VueRouter)

const baseRoutes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/UI/Home.vue')
    },
    {
        path: '/',
        name: 'Login',
        component: () => import('@/views/UI/Login.vue')
    },
    {
        path: '/404',
        name: 'Page404',
        component: () => import('@/views/UI/PageNotFound.vue')
    },
    {
        path: '/sanctum',
        name: 'LoginSanctum',
        component: () => import('@/views/UI/LoginSanctum.vue')
    },
    {
        path: '/password-reset/:token',
        name: 'PasswordReset',
        component: () => import('@/views/UI/PasswordReset.vue')
    },
    {
        path: '/documentation',
        name: 'Documentation',
        component: () => import('@/views/UI/Docs.vue')
    }
]

const routes = baseRoutes.concat(
    postRoutes,
    formRoutes,
    adminRoutes,
    companyRoutes
)

routes.push({ path: '*', name: 'PageNotFound', component: PageNotFound })

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token')
    var passwordResetComponent = 'PasswordReset'
    var loginComponent = 'LoginSanctum'
    var homeComponent = 'Home'

    if (
        to.name !== passwordResetComponent &&
        to.name !== loginComponent &&
        (!token || token.length <= 0)
    )
        next({ name: loginComponent })
    else if (
        (to.name === loginComponent && token) ||
        (to.name === passwordResetComponent && token)
    )
        next({ name: homeComponent })
    else next()
})

router.afterEach((to, from, next) => {
    //Handle switch of active li in menu
    $('#js-nav-menu li.active').removeClass('active')
    $('#js-nav-menu li:has(a[href="' + to.path + '"])').addClass('active')
})

export default router
