<template>
  <l-polygon :lat-lngs="poi.coordinates" color="#2E59D8" :visible="visible">
    <l-tooltip>
      <div>
        <h4>{{ poi.name }}</h4>
        <p>{{ poi.description }}</p>
      </div>
    </l-tooltip>
  </l-polygon>
</template>

<script>
import {LPolygon, LTooltip} from 'vue2-leaflet'

export default {
  name: 'MapPolygon',
  props: {
    poi: {
      type: Object,
      required: true,
      description: 'the polygon to show on the map'
    },
    poiIndex: {
      type: Number,
      required: true,
      description: 'index of the poi in the store'
    },
    visible: {
      type: Boolean,
      required: false,
      default: true,
      description: 'should the point be rendered ?'
    }
  },
  components: {
    LPolygon,
    LTooltip
  },
}
</script>

