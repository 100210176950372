export default {
    setForms(state, data) {
        state.forms = data;
    },
    setForm(state, data) {
        state.form = data;
    },
    setFormsTypes(state, data) {
        state.types = data;
    },
    setNewForm(state, data) {
        state.newForm = data;
    },
};
