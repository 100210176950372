export default {
    setUsers(state, data) {
        state.users = data
    },
    setUsersFilters(state, data) {
        state.usersFilters = data
    },
    setNewUser(state, data) {
        state.newUser = data
    },
    setNewWebhook(state, data) {
        state.newWebhook = data
    },
    setCompany(state, { company }) {
        state.company = company
    },
    setCompanySettings(state, company) {
        state.companySettings = company
    },
    SetCompanyHeaderMail(state, company) {
        state.companyHeaderMail = company
    }
}
