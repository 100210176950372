export default () => ({
    total: null,
    postsDashboard: null,
    post: null,
    hasOperator: false,
    newPost: null,
    history: null,
    sending: false,
    postHistories: null,
    postsList: null,
    statuses: null,
    posts: null,
    myPosts: null,
    usersList: null,
    postsConfig: {
        include: 'form,status,company,createdBy,racis',
        sort: 'id',
        sortDirection: 'desc',
        page: 1,
        filters: {},
        per_page: 15
    },
    myPostsConfig: {
        include: 'answers',
        per_page: 10,
        filters: {}
    },
    postsFilters: null,
    isPostRaciLoaded: false
})
