<template>
    
</template>
<script>
import {
    EventManager
} from '@/components/EventManager.js'

import * as Toastr from 'toastr';

export default {
    name: 'Toaster',
    components: {},
    data: function () {
        return {}
    },
    created() {
        Toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-right",
            "preventDuplicates": true,
            "onclick": null,
            "showDuration": 300,
            "hideDuration": 100,
            "timeOut": 3500,
            "extendedTimeOut": 1000,
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
    },
    methods: {
        fire: function (type, message1, message2) {
            Toastr[type](message1, message2)

        }
    },
    mounted() {

    }
}
</script>
