<template>
  <section id="wizard" class="mt-2">
    <b-steps
        v-model="activeStep"
        :animated="isAnimated"
        :has-navigation="hasNavigation"
        :icon-next="nextIcon"
        :icon-prev="prevIcon"
        :label-position="labelPosition"
        :mobile-mode="mobileMode"
        :rounded="isRounded"
        class="mt-2"
    >
      <b-step-item
          type="is-primary"
          v-for="(step, index) in form.steps"
          :key="step.id"
          :clickable="isStepsClickable[index - 1]"
          :label="step.name"
          :step="1 + index"
      >
        <FormStep ref="step" :step="step" :stepIndex="index"/>
      </b-step-item>

      <b-step-item icon="check">
        <FormStepSuccess></FormStepSuccess>
        <PushNotifPopUp v-if="isMessagingSupported" class="w-full"></PushNotifPopUp>
      </b-step-item>

      <template #navigation="{ previous, next }">
        <div v-if="packageForReborn">
          <b-button
              v-show='activeStep < form.steps.length'
              ref='prevBtn'
              :disabled='previous.disabled'
              type='is-primary is-light'
              @click.prevent='previous.action'
          >
            {{ $t('wizard.previous') }}
          </b-button>
          <b-button
              id='nextBtn'
              v-show='activeStep < form.steps.length - 1'
              ref='nextBtn'
              :disabled='next.disabled'
              outlined
              type='is-primary is-light'
              @click.native='scrollToTop()'
              @click.prevent='goNext(next)'
          >
            {{ $t('wizard.next') }}
          </b-button>
          <b-button
              id='sendBtn'
              v-show='activeStep === form.steps.length - 1'
              ref='nextBtn'
              type='is-primary'
              @click.prevent='sendPostHandler'
          >
            {{ $t('wizard.send') }}
          </b-button>
        </div>

        <div v-else
            class="navigation p-2 mx-auto z-50 w-full flex justify-between sticky bottom-0"
        >
          <button
              v-show="activeStep < form.steps.length"
              ref="prevBtn"
              id="vue-app-btn-previous"
              :disabled="previous.disabled"
              @click.prevent="previous.action"
              class="text-2xl bg-gray-400 px-4 py-2 mr-2 rounded-full text-white border hover-bg-secondaryColor"
          >
            <i class="fas fa-arrow-circle-left"></i>
          </button>
          <button
              v-show="activeStep < form.steps.length - 1"
              ref="nextBtn"
              id="nextBtn"
              :disabled="next.disabled"
              class="text-2xl bg-themeColor px-4 py-2 mr-2 rounded-full text-white hover-bg-secondaryColor"
              @click.prevent="goNext(next)"
          >
            <i class="fas fa-arrow-circle-right"></i>
          </button>
          <button
              v-show="activeStep === form.steps.length - 1"
              ref="nextBtn"
              id="sendBtn"
              class="text-2xl bg-themeColor px-4 py-2 mr-2 rounded-full text-white hover-bg-secondaryColor"
              @click.prevent="sendPostHandler"
          >
            <i class="fas fa-arrow-circle-right"></i>
          </button>
        </div>
      </template>
      </b-steps>

    <b-loading
        v-model="sending"
        :can-cancel="false"
        :is-full-page="true"
    ></b-loading>
  </section>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import FormStep from './components/Model/Form/FormStep'
import PushNotifPopUp from './components/Model/Form/PushNotifPopUp'
import FormStepSuccess from './components/Model/Form/FormStepSuccess'
import ToastableMixin from './components/UI/Utils/ToastableMixin'

export default {
  name: 'Wizard',
  mixins: [ToastableMixin],
  components: {
    FormStepSuccess,
    FormStep,
    PushNotifPopUp
  },
  data() {
    return {
      isCompletedForm: false,
      showSocial: false,
      isAnimated: true,
      isRounded: true,
      customNavigation: false,
      isProfileSuccess: false,

      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist'
    }
  },
  created() {
    this.activeStep = 0
    this.form.steps.forEach(step => {
      this.$store.state.wizard.isStepsClickable.push(false)
    })
  },
  computed: {
    ...mapState('form', {
      form: state => state.form
    }),
    ...mapState('post', {
      post: (state) => state.post
    }),
    ...mapState({
      isMessagingSupported: (state) => state.isMessagingSupported
    }),
    ...mapState('wizard', {
      hasNavigation: state => state.hasNavigation,
      isStepsClickable: state => state.isStepsClickable,
      packageForReborn: state => state.packageForReborn
    }),
    activeStep: {
      get() {
        return this.$store.state.wizard.activeStep
      },
      set(value) {
        this.$store.commit('wizard/setCurrentStep', value)
      }
    },
    sending: {
      get() {
        return this.$store.state.post.sending
      },
      set(value) {
        this.$store.commit('post/setSending', value)
      }
    },
    ...mapGetters('form', {
      form: 'getForm'
    })
  },
  methods: {
    ...mapActions('post', ['sendPost', 'updatePost']),
    goNext(next) {
      this.$refs.step[this.activeStep]
          .validate()
          .then(() => {
            next.action()
          })
          .catch(error => {
            console.log(error)
          })
    },
    sendPostHandler() {
      //validate last step before sending post
      this.$refs.step[this.activeStep]
          .validate()
          .then(() => {
            console.log(this.form.id)
            this.sending = true
            //TODO: add recaptcha validation before sending post
            let endpoint =
                this.post.id && this.post.id > 0
                    ? this.updatePost(this.form.id)
                    : this.sendPost(this.form.id)
            endpoint
                .then(() => {
                  this.activeStep = this.$refs.step.length + 1
                  if(!this.packageForReborn){
                    this.$store.commit('auth/setWeakConnection', true)
                  }

                })
                .catch(error => {
                  if (
                      error.response &&
                      error.response.data &&
                      error.response.data.errors
                  ) {
                    this.handleValidationError(error.response.data)
                    this.showValidationError(error.response.data)
                  } else {
                    this.$store.dispatch('toastError', error)
                  }
                })
                .finally(() => {
                  this.sending = false
                })
          })
          .catch(error => {
            console.log(error)
          })
    },
    handleValidationError(data) {
      var minIndexStep = -1

      for (const [key, value] of Object.entries(data.errors)) {
        var error = key.split('.')
        var attributeId = error[error.length - 1]

        for (var i = 0; i < this.form.steps.length; i++) {
          var step = this.form.steps[i]
          var indexAttribute = step.attributes.findIndex(
              attribute => attribute.id === parseInt(attributeId)
          )

          if (indexAttribute >= 0) {
            if (i < minIndexStep || minIndexStep === -1)
              minIndexStep = i

            break
          }
        }
      }

      if (minIndexStep !== -1) this.activeStep = minIndexStep
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style scoped>
@media (max-width: 768px) {
  .navigation {
    position: fixed;
  }
}
</style>
