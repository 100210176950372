<template>
  <main id="js-page-content" class="page-content" role="main">
    <!-- Entete-->
    <div class="subheader hidden-lg-up d-flex">
      <portal to="ariane">
        <h1 class="subheader-title mb-2 text-blue-600 hidden lg:block">
          <i class="fal fa-tachometer-alt text-blue-600 m-2"></i> 404 - Page not
          found
        </h1>
      </portal>
      <portal-target class="d-flex" name="ariane"></portal-target>
    </div>

    <div class="d-flex align-items-center justify-content-center mb-5">
      <div>
        <img class="m-auto w-100" src="/img/desert.jpg" />
        <div class="text-center">
          <h3 class="h3">{{ $t('404.title') }}</h3>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-center mt-5">
      <div class="mt-5">
        <router-link to="/" class="btn btn-primary">
          {{ $t('404.link') }}
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'PageNotFound',
}
</script>

<style scoped>

img {
  clip-path: ellipse(50% 45% at 50% 65%);
  z-index: 3;
}
</style>
