<template>
  <div>Bonjour, je suis Rating</div>
</template>

<script>
import FormItemMixin from '../FormItemMixin'
import HeaderFormItem from '../HeaderFormItem'

export default {
  name: 'RatingFormItem',
  components: {},
  mixins: [FormItemMixin]
}
</script>

<style scoped></style>
