import axios from 'axios'

import ImpersonateService from '@/services/Impersonate/ImpersonateService'

const companies = [
    { id: 1, name: 'Company One', status: { id: 1, name: 'Active' } },
    { id: 2, name: 'Company Two', status: { id: 1, name: 'Active' } },
    { id: 3, name: 'Company Three', status: { id: 1, name: 'Active' } }
]
const statuses = [
    { id: 1, name: 'Activé' },
    { id: 2, name: 'Désactivé' }
]
const formTypes = [
    { id: 1, name: 'Activé' },
    { id: 2, name: 'Désactivé' }
]
function prepareParams(config) {
    return new Promise((resolve, reject) => {
        var field = config.sort

        if (config.sortDirection === 'desc') {
            field = '-' + field
        }

        var params = {
            page: config.page,
            include: config.include,
            sort: field,
            per_page: config.per_page
        }

        if (config.filters) {
            for (const [key, value] of Object.entries(config.filters)) {
                params[`filter[${key}]`] = value
            }
        }

        resolve(params)
    })
}

export default {
    getCompanies({ state, rootState, commit }) {
        return new Promise((resolve, reject) => {
            try {
                prepareParams(state.companiesConfig).then((params) => {
                    const response = {
                        data: companies,
                        total: 3,
                        per_page: 15,
                        current_page: 1
                    }

                    commit('setCompanies', response)
                    resolve(response)
                })
            } catch (error) {
                reject(error)
            }
        })
    },
    updateStatus({ state, rootState, commit }, data) {
        return new Promise((resolve, reject) => {
            resolve()
        })
    },
    getCompany({ state, rootState, commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            commit('setCompany', companies[id - 1])
            commit('setStatuses', statuses)
            resolve()
        })
    },
    getCompaniesFilters({ state, rootState, commit }, id) {
        return new Promise((resolve, reject) => {
            commit('setCompaniesFilters', { formTypes })
            resolve()
        })
    },
    createCompany({ state, rootState, commit }, data) {
        return new Promise((resolve, reject) => {
            resolve()
        })
    },
    updateCompany({ state, rootState, commit }, data) {
        return new Promise((resolve, reject) => {
            resolve()
        })
    },
    deleteTemplate({ state, rootState, commit }, data) {
        return new Promise((resolve, reject) => {
            try {
                axios
                    .delete(`${rootState.baseURL}/response-template/${data.id}`)
                    .then((response) => {
                        commit('deleteTemplate', data)
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            } catch (error) {
                reject(error)
            }
        })
    },
    getTemplates({ state, rootState, commit }) {
        return new Promise((resolve, reject) => {
            try {
                prepareParams(state.templatesConfig).then((params) => {
                    axios
                        .get(`${rootState.baseURL}/response-template`, {
                            params
                        })
                        .then((response) => {
                            commit('setTemplates', response.data.data.data)
                            commit('setPagination', response.data.data)
                            resolve(response)
                        })
                        .catch((error) => {
                            reject(error)
                        })
                })
            } catch (error) {
                reject(error)
            }
        })
    },
    getTemplatesFilters({ state, rootState, commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${rootState.baseURL}/templates/filters`)
                .then((response) => {
                    commit('setTemplatesFilters', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    postTemplate({ state, rootState, commit }, content) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${rootState.baseURL}/response-template`, content)
                .then((response) => {
                    commit('addTemplate', content)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getTemplate({ state, rootState, commit }, id) {
        return new Promise((resolve, reject) => {
            try {
                axios
                    .get(`${rootState.baseURL}/response-template/${id}/get`)
                    .then((response) => {
                        commit('setTemplate', response.data.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            } catch (error) {
                reject(error)
            }
        })
    },
    editTemplate({ state, rootState, commit }, data) {
        return new Promise((resolve, reject) => {
            try {
                axios
                    .put(
                        `${rootState.baseURL}/response-template/${data.id}`,
                        data.template
                    )
                    .then((response) => {
                        commit('setTemplate', response.data.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            } catch (error) {
                reject(error)
            }
        })
    },
    impersonateUser({ commit }, userId) {
        return new ImpersonateService()
            .impersonateUser(userId)
            .then((data) => commit('setImpersonation', data))
    },
    leaveImpersonate({ commit }) {
        return new ImpersonateService()
            .leaveImpersonate()
            .then(() => commit('leaveImpersonation'))
    }
}
