class GMaps {
    constructor(mapElementId, config) {
        this.map = new google.maps.Map(document.getElementById(mapElementId))
        this.geocoder = new google.maps.Geocoder()
        this.autocompleteService = new google.maps.places.AutocompleteService()
        this.restrictedCountry = config.restriction.city
        this.restrictedCity = config.restriction.country
    }

    geocodeAddress(address) {
        return new Promise((resolve, reject) => {
            this.geocoder.geocode({ address: address }, (results, status) => {
                if (status === 'OK') {
                    if (results.length > 0) {
                        const geocodedAddress = {
                            address: results[0].formatted_address,
                            lat: results[0].geometry.location.lat(),
                            lon: results[0].geometry.location.lng()
                        }
                        resolve(geocodedAddress)
                    } else {
                        reject(new Error('No results found for the address'))
                    }
                } else {
                    if (status !== 'ZERO_RESULTS') {
                        reject(
                            new Error(
                                'Geocode was not successful for the following reason: ' +
                                    status
                            )
                        )
                    }
                }
            })
        })
    }

    autocompleteAddress(input, callback) {
        const request = {
            input: `${this.restrictedCountry} ${this.restrictedCity} ${input}`
        }

        this.autocompleteService.getPlacePredictions(
            request,
            (predictions, status) => {
                if (status === 'OK') {
                    callback(predictions)
                } else {
                    callback([])
                }
            }
        )
    }

    reverseGeocode(latitude, longitude) {
        return new Promise((resolve, reject) => {
            const latLng = new google.maps.LatLng(latitude, longitude)
            this.geocoder.geocode({ location: latLng }, (results, status) => {
                if (status === 'OK') {
                    if (results.length > 0) {
                        const reverseGeocodedAddress = {
                            address: results[0].formatted_address,
                            lat: latitude,
                            lon: longitude
                        }
                        resolve(reverseGeocodedAddress)
                    } else {
                        reject(
                            new Error(
                                'No results found for the given coordinates'
                            )
                        )
                    }
                } else {
                    reject(
                        new Error(
                            'Reverse geocode was not successful for the following reason: ' +
                                status
                        )
                    )
                }
            })
        })
    }
}

export default GMaps
