<template>
<div class="">
    <a href="#" class="header-icon" data-toggle="dropdown" title="My Apps" aria-expanded="false">
        <i class="fal fa-cube"></i>
    </a>
    <div class="dropdown-menu dropdown-menu-animated w-auto h-auto" style="">
        <div class="dropdown-header bg-trans-gradient d-flex justify-content-center align-items-center rounded-top">
            <h4 class="m-0 text-center color-white">
                Quick Shortcut
                <small class="mb-0 opacity-80">User Applications &amp; Addons</small>
            </h4>
        </div>
        <div class="custom-scroll h-100">
            <div class="slimScrollDiv" style="position: relative; overflow: hidden; width: auto; height: 100%;">
                <ul class="app-list" style="overflow: hidden; width: auto; height: 100%;">
                    <li>
                        <a href="#" class="app-list-item hover-white">
                            <span class="icon-stack">
                                <i class="base-2 icon-stack-3x color-primary-600"></i>
                                <i class="base-3 icon-stack-2x color-primary-700"></i>
                                <i class="ni ni-settings icon-stack-1x text-white fs-lg"></i>
                            </span>
                            <span class="app-list-name">
                                Services
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="app-list-item hover-white">
                            <span class="icon-stack">
                                <i class="base-2 icon-stack-3x color-primary-400"></i>
                                <i class="base-10 text-white icon-stack-1x"></i>
                                <i class="ni md-profile color-primary-800 icon-stack-2x"></i>
                            </span>
                            <span class="app-list-name">
                                Account
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="app-list-item hover-white">
                            <span class="icon-stack">
                                <i class="base-9 icon-stack-3x color-success-400"></i>
                                <i class="base-2 icon-stack-2x color-success-500"></i>
                                <i class="ni ni-shield icon-stack-1x text-white"></i>
                            </span>
                            <span class="app-list-name">
                                Security
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="app-list-item hover-white">
                            <span class="icon-stack">
                                <i class="base-18 icon-stack-3x color-info-700"></i>
                                <span class="position-absolute pos-top pos-left pos-right color-white fs-md mt-2 fw-400">28</span>
                            </span>
                            <span class="app-list-name">
                                Calendar
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="app-list-item hover-white">
                            <span class="icon-stack">
                                <i class="base-7 icon-stack-3x color-info-500"></i>
                                <i class="base-7 icon-stack-2x color-info-700"></i>
                                <i class="ni ni-graph icon-stack-1x text-white"></i>
                            </span>
                            <span class="app-list-name">
                                Stats
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="app-list-item hover-white">
                            <span class="icon-stack">
                                <i class="base-4 icon-stack-3x color-danger-500"></i>
                                <i class="base-4 icon-stack-1x color-danger-400"></i>
                                <i class="ni ni-envelope icon-stack-1x text-white"></i>
                            </span>
                            <span class="app-list-name">
                                Messages
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="app-list-item hover-white">
                            <span class="icon-stack">
                                <i class="base-4 icon-stack-3x color-fusion-400"></i>
                                <i class="base-5 icon-stack-2x color-fusion-200"></i>
                                <i class="base-5 icon-stack-1x color-fusion-100"></i>
                                <i class="fal fa-keyboard icon-stack-1x color-info-50"></i>
                            </span>
                            <span class="app-list-name">
                                Notes
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="app-list-item hover-white">
                            <span class="icon-stack">
                                <i class="base-16 icon-stack-3x color-fusion-500"></i>
                                <i class="base-10 icon-stack-1x color-primary-50 opacity-30"></i>
                                <i class="base-10 icon-stack-1x fs-xl color-primary-50 opacity-20"></i>
                                <i class="fal fa-dot-circle icon-stack-1x text-white opacity-85"></i>
                            </span>
                            <span class="app-list-name">
                                Photos
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="app-list-item hover-white">
                            <span class="icon-stack">
                                <i class="base-19 icon-stack-3x color-primary-400"></i>
                                <i class="base-7 icon-stack-2x color-primary-300"></i>
                                <i class="base-7 icon-stack-1x fs-xxl color-primary-200"></i>
                                <i class="base-7 icon-stack-1x color-primary-500"></i>
                                <i class="fal fa-globe icon-stack-1x text-white opacity-85"></i>
                            </span>
                            <span class="app-list-name">
                                Maps
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="app-list-item hover-white">
                            <span class="icon-stack">
                                <i class="base-5 icon-stack-3x color-success-700 opacity-80"></i>
                                <i class="base-12 icon-stack-2x color-success-700 opacity-30"></i>
                                <i class="fal fa-comment-alt icon-stack-1x text-white"></i>
                            </span>
                            <span class="app-list-name">
                                Chat
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="app-list-item hover-white">
                            <span class="icon-stack">
                                <i class="base-5 icon-stack-3x color-warning-600"></i>
                                <i class="base-7 icon-stack-2x color-warning-800 opacity-50"></i>
                                <i class="fal fa-phone icon-stack-1x text-white"></i>
                            </span>
                            <span class="app-list-name">
                                Phone
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="app-list-item hover-white">
                            <span class="icon-stack">
                                <i class="base-6 icon-stack-3x color-danger-600"></i>
                                <i class="fal fa-chart-line icon-stack-1x text-white"></i>
                            </span>
                            <span class="app-list-name">
                                Projects
                            </span>
                        </a>
                    </li>
                    <li class="w-100">
                        <a href="#" class="btn btn-default mt-4 mb-2 pr-5 pl-5 waves-effect waves-themed"> Add more apps </a>
                    </li>
                </ul>
                <div class="slimScrollBar" style="background: rgba(0, 0, 0, 0.6); width: 4px; position: absolute; top: 0px; opacity: 0.4; display: block; border-radius: 7px; z-index: 99; right: 4px; height: 237.798px;"></div>
                <div class="slimScrollRail" style="width: 4px; height: 100%; position: absolute; top: 0px; display: none; border-radius: 7px; background: rgb(250, 250, 250); opacity: 0.2; z-index: 90; right: 4px;"></div>
            </div>
        </div>
    </div>
</div>
</template>


<script>

export default {
    name: 'AppShortcut',
}
</script>
