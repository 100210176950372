<template>
    <div>
        <div class="flex items-center w-full">
            <i
                :class="
                    attribute.response_private == 1
                        ? 'fas fa-lock px-1 pt-2'
                        : ' '
                "
            ></i>
            <b-field
                :message="message"
                type="is-danger font-bold text-center"
                class="w-full"
            >
                <b-field
                    :label="`${
                        attribute.label ? attribute.label + asterisk : ''
                    }`"
                >
                    <template #message>
                        <span
                            v-html="
                                attribute.description
                                    ? attribute.description
                                    : ''
                            "
                        ></span>
                    </template>
                    <b-checkbox v-model="result" class="gdprInput"
                        >{{
                            attribute.instruction
                                ? attribute.instruction
                                : attribute.label
                        }}{{ attribute.label ? '' : asterisk }}
                    </b-checkbox>
                </b-field>
            </b-field>
        </div>
    </div>
</template>

<script>
import FormItemMixin from '../FormItemMixin'

export default {
    name: 'GdprFormItem',
    mixins: [FormItemMixin],
    watch: {
        result: function (newValue) {
            this.doSave({
                id: this.attribute.id,
                type_id: this.attribute.form_attribute_type_id,
                value: newValue
            })
        }
    },
    data() {
        return {}
    },
  created() {
    if (this.shouldInitialize()) {
      if (
          this.$store.state.post.post.attributes[this.attribute.id].value
      ) {
        this.result =
            this.$store.state.post.post.attributes[
                this.attribute.id
                ].value
      }
    }},
}
</script>

<style scoped></style>
