export default [
    {
        path: '/admin/companies',
        name: 'AdminCompanies',
        component: () => import('@/views/Admin/Companies.vue')
    },
    {
        path: '/admin/companies/create',
        name: 'CreateCompany',
        component: () => import('@/views/Admin/CompanyBuilder.vue')
    },
    {
        path: '/admin/companies/:id',
        name: 'Company',
        component: () => import('@/views/Admin/Company.vue')
    },
    {
        path: '/admin/companies/:companyId/edit',
        name: 'EditCompany',
        component: () => import('@/views/Admin/CompanyBuilder.vue')
    },
    {
        path: '/admin/templates',
        name: 'Templates',
        component: () => import('@/views/Admin/Templates.vue')
    },
    {
        path: '/admin/template/create',
        name: 'CreateTemplate',
        component: () => import('@/views/Admin/CreateTemplate.vue')
    },
    {
        path: '/admin/templates/:id/edit',
        name: 'EditTemplate',
        component: () => import('@/views/Admin/EditTemplate.vue')
    }
]
