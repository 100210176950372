<template>
    <div>
        <button
            type="button"
            data-toggle="dropdown"
            aria-expanded="false"
            class="m-4 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2"
        >
            {{ $t('topbar.new') }}
            <icon-chevron-bas class="h-5 w-5" />
        </button>
        <ul class="dropdown-menu">
            <!-- LTU  
      <li><router-link to="/users/create" class="dropdown-item">Citoyen</router-link></li>
-->
            <li>
                <router-link to="/post/create/forms" class="dropdown-item">{{
                    $t('topbar.ticket')
                }}</router-link>
            </li>
            <li>
                <router-link to="/forms/create" class="dropdown-item">{{
                    $t('topbar.form')
                }}</router-link>
            </li>
            <li>
                <router-link
                    to="/admin/template/create"
                    class="dropdown-item"
                    >{{ $t('topbar.template') }}</router-link
                >
            </li>
        </ul>
    </div>
</template>

<script>
import iconChevronBas from '@/components/icons/iconChevronBas.vue'
export default {
    name: 'Create',
    components: { iconChevronBas }
}
</script>
