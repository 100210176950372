<template>
  <div class="pb-2">
    <b-field>
      <b-autocomplete
        id="adress"
        class="addressInput"
        v-model="result.value.address"
        :data="locationsResult"
        :loading="isFetching"
        :open-on-focus="true"
        expanded
        field="value"
        icon="map-marker-alt"
        icon-right="close-circle"
        icon-right-clickable
        placeholder="Adresse"
        @select="(option) => setSelected(option)"
        @typing="getAsyncData"
      >
        <template slot-scope="props">
          <div class="media">
            <div class="media-left">
              <i class="fas fa-map-marker-alt is-primary"></i>
            </div>
            <div class="media-content">
              {{ props.option.address }}
            </div>
          </div>
        </template>
      </b-autocomplete>

      <p class="control">
        <b-button
          id="geolocation"
          class="h-full text-white"
          icon-left="street-view"
          :label="labelposition"
          type="is-primary"
          @click.prevent="locateUser"
        />
      </p>
    </b-field>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { latLng } from "leaflet";

export default {
  name: "LocationFormItem",
  components: {},
  props: ["result", "provider", "gmaps"],
  data() {
    return {
      locationsResult: [],
      isFetching: false,
      labelposition: "",
      resultat: "",
      searchInput: "",
    };
  },
  created() {
    this.resultat = this.result;
    if (screen.width > 700) {
      this.labelposition = this.$t("location.use_current_location");
    } else {
      this.labelposition = this.$t("location.use_current_location");
    }
  },
  computed: {
    postalAddress: {
      get() {
        return this.$store.state.map.postalAddress;
      },
      set(value) {
        this.$store.commit("map/setPostalAddress", value);
      },
    },
  },
  methods: {
    ...mapActions("map", [
      "setLocation",
      "setUserLocation",
      "searchPlacesFromPostalAddress",
    ]),
    getAsyncData: function (name) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.resultat.value.address = name;
      if (this.provider !== "maps") {
        this.setSelected({ address: name });
      } else if (this.provider === "maps" && name.length > 1) {
        this.setSelected({ address: name });
      }
      this.timeout = setTimeout(() => {
        this.searchPlaces(name);
      }, 600);
    },

    locateUser: function () {
      this.setUserLocation().then((coords) => {
        this.$emit("onUserLocated", coords);
      });
    },
    searchPlaces: function (name) {
      this.postalAddress = name;
      if (this.provider !== "maps") {
        this.isFetching = true;
        if (this.postalAddress.length > 0)
          this.searchPlacesFromPostalAddress(this.postalAddress)
            .then((results) => {
              if (results.length > 0) {
                this.locationsResult = results;
              } else {
                this.resultat.value.address = name;
                this.setSelected({ address: name });
              }
            })
            .finally(() => {
              this.isFetching = false;
            });
      } else {
        this.locationsResult = [];
        if (this.postalAddress.length > 0) {
          this.gmaps.autocompleteAddress(
            this.postalAddress,
            (locationsResult) => {
              locationsResult.forEach((location) => {
                this.locationsResult.push({
                  address: location.description,
                });
              });
            }
          );
          this.$store.commit("map/setLocationResult", this.locationsResult);
        } else {
          this.locationsResult = [];
        }
      }
    },
    setSelected: function (location) {
      if (this.provider !== "maps") {
        this.postalAddress = location;
        this.$emit("onLocationSelected", location);

        if (location.lat && location.lon) {
          this.setLocation(latLng(location.lat, location.lon)).then(() => {
            var config = this.$store.state.map.config;
            config.center = [location.lat, location.lon];

            this.$store.commit("map/setConfig", config);
          });
        }
      } else {
        this.postalAddress = location.address;
        this.locationsResult = [];
        this.gmaps
          .geocodeAddress(location.address)
          .then((results) => {
            this.$emit("onLocationSelected", {
              address: location.address,
              lat: results.lat,
              lon: results.lon,
            });
            this.setLocation(latLng(results.lat, results.lon)).then(() => {
              var config = this.$store.state.map.config;
              config.center = [results.lat, results.lon];

              this.$store.commit("map/setConfig", config);
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
