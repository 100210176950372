<template>
  <div class="block">
    <HeaderFormItem
        :instruction="`${
                attribute.instruction ? attribute.instruction : ''
            } ${requiredasterisk}`"
        :description="`${
                attribute.description ? attribute.description : ''
            } `"
        :required="`${requiredmessage} `"
    >
    </HeaderFormItem>
    <div class="flex-column items-center w-full">
      <div class="mb-2 flex items-center">
        <i
            :class="
                        attribute.response_private == 1 ? 'fas fa-lock px-1 pb-2' : ' '
                    "
        ></i>
        <label class="text-gray-700 text-base font-bold mb-2">
          {{ attribute.label ? attribute.label : '' }} {{ asterisk }}
        </label>
      </div>
      <div class="flex items-center mb-4">
        <div class="flex items-center mr-4" v-for="option in options">
          <input
              :id="option"
              type="radio"
              :value="option"
              :name="`inline-radio-group-${inputNumber}`"
              class="hidden"
              v-model="result"
          />
          <label
              :for="option"
              class="flex items-center cursor-pointer text-l ml-2 text-gray-900 dark:text-gray-300"
          >
                        <span
                            class="radioInput w-6 h-6 inline-block mr-2 rounded-full border border-gray-500 flex-no-shrink"
                        ></span
                        >{{ option }}</label
          >
        </div>
      </div>
    </div>

    <b-field :message="message" type="is-danger font-bold text-center">
    </b-field>
  </div>
</template>


<script>
import FormItemMixin from '../FormItemMixin'
import HeaderFormItem from '../HeaderFormItem'

export default {
  name: 'RadioFormItem',
  components: {
    HeaderFormItem
  },
  mixins: [FormItemMixin],
  data: function () {
    return {
      options: [],
      inputNumber: 0
    }
  },
  watch: {
    result: function (newValue) {
      this.doSave({
        id: this.attribute.id,
        type_id: this.attribute.form_attribute_type_id,
        value: newValue
      })
    }
  },
  created() {
    this.getOptions()
    this.inputNumber = parseInt(Math.random() * 100)
  },
  methods: {
    getOptions() {
      if (
          this.attribute.config.options.hasOwnProperty(this.$i18n.locale)
      ) {
        this.options = this.attribute.config.options[this.$i18n.locale]
      } else {
        this.options =
            this.attribute.config.options[
                Object.keys(this.attribute.config.options)[0]
                ]
      }
    }
  }
}
</script>
