import axios from 'axios'
axios.defaults.headers.common['Accept'] = 'application/json'

function prepareParams(config) {
    return new Promise((resolve, reject) => {
        var field = config.sort

        if (config.sortDirection === 'desc') {
            field = '-' + field
        }

        var params = {
            page: config.page,
            include: config.include,
            sort: field,
            per_page: config.per_page
        }

        if (config.filters) {
            for (const [key, value] of Object.entries(config.filters)) {
                params[`filter[${key}]`] = value
            }
        }

        resolve(params)
    })
}

/**
 * Forms don't need pagination, they should always be less than 10
 * @param context
 * @returns {Promise<unknown>}
 */
export default {
    getApplications({ state, rootState, commit }, formId) {
        return new Promise((resolve, reject) => {
            prepareParams(state.applicationsConfig).then((params) => {
                axios
                    .get(`${rootState.baseURL}/forms/${formId}/applications`, {
                        params
                    })
                    .then((response) => {
                        commit('setApplications', response.data.apps)

                        resolve()
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        })
    },
    getApplication({ state, rootState, commit }, app) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${rootState.baseURL}/forms/${app.formId}/applications/${app.id}`
                )
                .then((response) => {
                    commit('setApplication', response.data.app)

                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    createApplication({ state, rootState, commit }, formId) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${rootState.baseURL}/forms/${formId}/applications`,
                    state.application
                )
                .then((response) => {
                    commit('setApplication', response.data.app)
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    editApplication({ state, rootState, commit }, app) {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    `${rootState.baseURL}/forms/${app.formId}/applications/${app.id}`,
                    state.application
                )
                .then((response) => {
                    commit('setApplication', response.data.app)
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    download({ state, rootState, commit }, app) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${rootState.baseURL}/forms/${app.formId}/applications/${app.id}/download`
                )
                .then((response) => {
                    resolve(response.data.env)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    deleteApplication({ state, rootState }, app) {
        return new Promise((resolve, reject) => {
            axios
                .delete(
                    `${rootState.baseURL}/forms/${app.formId}/applications/${app.id}`
                )
                .then((response) => {
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}
