export default {
    setPostsDashboard(state, data) {
        state.postsDashboard = data
    },
    setPostsList(state, data) {
        state.postsList = data
    },
    setPostsTotal(state, data) {
        state.total = data
    },
    setPosts(state, data) {
        state.posts = data
    },
    setPostsWithMedias(state, data) {
        state.posts = data
    },
    setPost(state, data) {
        state.post = data
    },
    setPostsConfig(state, data) {
        state.postsConfig = data
    },
    setmyPostsConfig(state, data) {
        state.myPostsConfig = data
    },
    setPostsFilters(state, data) {
        state.postsFilters = data
    },
    setStatuses(state, data) {
        state.statuses = data
    },
    setMyPosts(state, data) {
        state.myPosts = data
    },
    setUsersList(state, data) {
        state.usersList = data
    },
    setPostHistories(state, data) {
        state.postHistories = data
    },
    setNewPost(state, data) {
        state.newPost = data
    },
    addAttribute(state, attribute) {
        if (!state.post.attributes.hasOwnProperty(attribute.id)) {
            state.post.attributes[attribute.id] = {}
        }

        state.post.attributes[attribute.id] = attribute
    },
    setSending(state, posts) {
        state.posts = posts
    },
    setHistory(state, data) {
        state.history = data
    },
    setHasOperator(state, data) {
        state.hasOperator = data
    },
    setIsPostRaciLoaded(state, data) {
        state.isPostRaciLoaded = data
    }
}
