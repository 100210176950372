export default [
    {
        path: '/forms',
        name: 'Forms',
        component: () => import('@/views/Form/Forms.vue')
    },
    {
        path: '/forms/create',
        name: 'FormBuilder',
        component: () => import('@/views/Form/FormBuilder.vue')
    },
    {
        path: '/forms/:id',
        name: 'Form',
        component: () => import('@/views/Form/Form.vue')
    },
    {
        path: '/forms/:formId/edit',
        name: 'EditForm',
        component: () => import('@/views/Form/FormBuilder.vue')
    },
    {
        path: '/forms/:id/app/create',
        name: 'CreateApplications',
        component: () => import('@/views/Form/FormApplications.vue')
    },
    {
        path: '/forms/:id/app/:reference/edit',
        name: 'EditApplications',
        component: () => import('@/views/Form/FormApplications.vue')
    }
]
