<template>
  <div>
    <HeaderFormItem
        :instruction="`${attribute.instruction} ${requiredasterisk}`"
        :description="`${
attribute.description ? attribute.description : ''
} `"
        :required="`${requiredmessage} `"
    >
    </HeaderFormItem>
    <div class="flex items-center w-full">
      <i
          :class="
                    attribute.response_private == 1
                        ? 'fas fa-lock px-1 pb-2'
                        : ' '
                "
      ></i>
      <label class="text-gray-700 text-base font-bold mb-2">
        {{ attribute.label ? attribute.label : '' }} {{ asterisk }}
      </label>
    </div>
    <textarea
        v-model="result"
        class="appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500"
        :placeholder="
                attribute.config.placeholder
                    ? attribute.config.placeholder[this.$i18n.locale]
                    : ''
            "
    />
    <p class="text-gray-700 text-xs mb-2">
      {{ attribute.help ? attribute.help : '' }}
    </p>
    <b-field :message="message" type="is-danger font-bold text-center">
    </b-field>
  </div>
</template>

<script>
import FormItemMixin from '../FormItemMixin'
import HeaderFormItem from '../HeaderFormItem'

export default {
  name: 'TextareaFormItem',
  components: {HeaderFormItem},
  mixins: [FormItemMixin],
  created() {
    if (this.shouldInitialize()) {
      this.saveUserAnswer({
        id: this.attribute.id,
        type_id: this.attribute.form_attribute_type_id,
        value: this.$store.state.post.post.attributes[this.attribute.id]
            .value
      }).then(() => {
        this.result =
            this.$store.state.post.post.attributes[
                this.attribute.id
                ].value
      })
    }
  },
  watch: {
    result: function (newValue) {
      this.doSave({
        id: this.attribute.id,
        type_id: this.attribute.form_attribute_type_id,
        value: newValue
      })
    }
  }
}
</script>


