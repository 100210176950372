<template>
  <section>
    <HeaderFormItem
        :instruction="`${
                attribute.instruction ? attribute.instruction : ''
            } ${requiredasterisk}`"
        :description="`${
                attribute.description ? attribute.description : ''
            } `"
        :required='`${requiredmessage} `'
    >
    </HeaderFormItem>
    <div class='flex items-center w-full'>
      <i
          :class="
                    attribute.response_private == 1
                        ? 'fas fa-lock px-1 pt-5'
                        : ' '
                "
      ></i>
      <b-field
          :label="attribute.label ? attribute.label : ''"
          :message="attribute.help ? attribute.help : ''"
          class='dateField'
      >
        <b-datetimepicker
            class='datetimeInput'
            v-model='selected'
            :placeholder="
                        attribute.config.placeholder &&
                        attribute.config.placeholder.length > 0
                            ? attribute.config.placeholder[this.$i18n.locale]
                            : ''
                    "
            icon='calendar-today'
            :icon-right="selected ? 'close-circle' : ''"
            icon-right-clickable
            @icon-right-click='clearDateTime'
            :locale='locale'
            :first-day-of-week='firstDayOfWeek'
            :datepicker='{ showWeekNumber }'
            :timepicker='{ enableSeconds, hourFormat }'
            horizontal-time-picker
            editable
        >
        </b-datetimepicker>
      </b-field>
    </div>
    <b-field :message='message' type='is-danger font-bold text-center'>
    </b-field>
  </section>
</template>

<script>
import FormItemMixin from '../FormItemMixin'
import HeaderFormItem from '../HeaderFormItem'
import moment from 'moment'

export default {
  name: 'DateTimeFormItem',
  components: {HeaderFormItem},
  mixins: [FormItemMixin],
  data() {
    return {
      selected: new Date(),
      showWeekNumber: false,
      enableSeconds: false,
      hourFormat:'24',
      neWDateHourFormat: 'YYYY-MM-DD HH:mm',
      firstDayOfWeek: 1
    }
  },
  created() {
    if (this.shouldInitialize()) {
      this.saveUserAnswer({
        id: this.attribute.id,
        type_id: this.attribute.form_attribute_type_id,
        value: this.$store.state.post.post.attributes[this.attribute.id]
            .value
      }).then(() => {
        this.selected = new Date(
            this.$store.state.post.post.attributes[
                this.attribute.id
                ].value
        )
      })
    } else {
      this.clearDateTime()
    }
  },
  computed: {
    locale() {
      if (this.$i18n.locale == 'fr') {
        this.firstDayOfWeek = 1
        this.hourFormat = '24'
        this.neWDateHourFormat = 'YYYY-MM-DD HH:mm'
        return 'fr-FR'
      } else {
        this.firstDayOfWeek = 0
        this.hourFormat = '12'
        this.neWDateHourFormat = 'YYYY-MM-DD hh:mm'
        return 'en-US'
      }
    }
  },
  methods: {
    clearDateTime() {
      this.selected = new Date()
      this.result = null
    }
  },
  watch: {
    selected: function (newValue) {
      if (
          isNaN(newValue) ||
          newValue === 'Invalid date' ||
          newValue === null
      ) {
        this.clearDateTime()
      } else {
        let newDate = moment(newValue).format(
            this.neWDateHourFormat
        )
        this.result = this.doSave({
          id: this.attribute.id,
          type_id: this.attribute.form_attribute_type_id,
          value: newDate
        })
      }
    }
  }
}
</script>
