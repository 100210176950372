<template>
  <div class="formStep">
    <div class="hero bgGray-100" >
      <div class="hero-body has-text-centered">
        <h2 class="formStepTitle wizardTextColor">
          {{ step.name }}
        </h2>
        <p class="formStepSubtitle">
          {{ step.content }}
        </p>

      </div>

      <section>
        <component
            :is="attribute.form_attribute_type.name"
            v-for="(attribute, index) in step.attributes"
            :key="attribute.id"
            ref="attributes"
            :attribute="attribute"
            :attributeIndex="index"
            :stepIndex="stepIndex"
            class="mb-4"
        />
      </section>
    </div>
  </div>
</template>


<script>
import LocationFormItem from './FormItem/LocationFormItem'
import CategoryFormItem from './FormItem/CategoryFormItem'
import GdprFormItem from './FormItem/GdprFormItem'
import SmallTextFormItem from './FormItem/SmallTextFormItem'
import EmailFormItem from './FormItem/EmailFormItem'
import MarkdownFormItem from './FormItem/MarkdownFormItem'
import FileUpload from './FormItem/FileUploadFormItem'
import RatingFormItem from './FormItem/RatingFormItem'
import SelectFormItem from './FormItem/SelectFormItem'
import CheckboxFormItem from './FormItem/CheckboxFormItem'
import RadioFormItem from './FormItem/RadioFormItem'
import DateFormItem from './FormItem/DateFormItem'
import DateTimeFormItem from './FormItem/DateTimeFormItem'
import NumberFormItem from './FormItem/NumberFormItem'
import TextareaFormItem from './FormItem/TextareaFormItem'

export default {
  name: 'FormStep',
  props: {
    step: {
      required: true,
      type: Object,
      description: 'A step with all its attributes'
    },
    stepIndex: {
      required: true,
      type: Number,
      description: 'Current step index from the steps array in Wizard'
    }
  },
  components: {
    location: LocationFormItem,
    category: CategoryFormItem,
    gdpr: GdprFormItem,
    smalltext: SmallTextFormItem,
    email: EmailFormItem,
    markdown: MarkdownFormItem,
    'file-upload': FileUpload,
    rating: RatingFormItem,
    'select-field': SelectFormItem,
    checkbox: CheckboxFormItem,
    radio: RadioFormItem,
    date: DateFormItem,
    datetime: DateTimeFormItem,
    number: NumberFormItem,
    'textarea-field': TextareaFormItem
  },
  created() {
    let shouldInitialize = false

    if (
        !this.$store.state.post.post ||
        !this.$store.state.post.post.attributes
    ) {
      shouldInitialize = true
    } else {
      let keys = Object.keys(this.$store.state.post.post.attributes)
      if (keys.length <= 0) {
        shouldInitialize = true
      }
    }

    if (shouldInitialize) {
      this.step.attributes.map(
          (attribute) =>
              (this.$store.state.post.post.attributes[attribute.id] = {
                id: attribute.id,
                step: this.step.id
              })
      )
    }
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        var isValid = !this.$refs.attributes.some(
            (attribute) => attribute.validate() === false
        )
        this.$store.commit('wizard/setIsStepsClickable', {
          index: this.$store.state.wizard.activeStep,
          value: isValid
        })

        isValid ? resolve() : reject()
      })
    }
  }
}
</script>

