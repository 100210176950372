<template>
  <div class="" v-if="user">
    <a
      href="#"
      data-toggle="dropdown"
      :title="user.email"
      class="header-icon d-flex align-items-center justify-content-center ml-2"
      aria-expanded="false"
    >
      <div v-if="user.profile_picture">
        <img
          :src="user.profile_picture"
          class="profile-image rounded-circle"
          :alt="user.name"
        />
      </div>
      <div v-else>
        <!-- <span class="ml-1 mr-1 text-truncate text-truncate-header hidden-xs-down">Bonjour, {{user.name}}</span> -->
        <!-- <i class="ni ni-chevron-down hidden-xs-down"></i> -->
        <span style="font-size: 2em" class="">
          <i class="fas fa-user-circle text-blue-600"></i>
        </span>
      </div>
    </a>
    <div class="dropdown-menu dropdown-menu-animated dropdown-lg" style="">
      <div
        class="dropdown-header bg-trans-gradient d-flex flex-row py-4 rounded-top"
      >
        <div class="d-flex flex-row align-items-center mt-1 mb-1 color-white">
          <span class="mr-2" v-if="user.profile_picture">
            <img
              :src="user.profile_picture"
              class="rounded-circle profile-image"
              :alt="user.name"
            />
          </span>
          <div class="info-card-text">
            <div class="fs-lg text-truncate text-truncate-lg">
              {{ user.name }}
            </div>
            <span class="text-truncate text-truncate-md opacity-80">{{
              user.email
            }}</span>
          </div>
        </div>
      </div>
      <div class="dropdown-divider m-0"></div>
      <a href="#" class="dropdown-item" data-action="app-fullscreen">
        <span data-i18n="drpdwn.fullscreen">{{ $t('topbar.profil.fullscreen') }}</span>
        <i class="float-right text-muted fw-n">F11</i>
      </a>
      <a href="#" class="dropdown-item" data-action="app-print">
        <span data-i18n="drpdwn.print">{{ $t('topbar.profil.print') }}</span>
        <i class="float-right text-muted fw-n">Ctrl + P</i>
      </a>
      <div class="dropdown-multilevel dropdown-multilevel-left">
        <div class="dropdown-item">{{ $t('topbar.profil.language') }}</div>
        <div class="dropdown-menu">
          <a
            href="#"
            class="dropdown-item"
            :class="{ active: lang === 'fr' }"
            @click="setLang('fr')"
            >Français</a
          >
          <a
            href="#"
            class="dropdown-item"
            :class="{ active: lang === 'en' }"
            @click="setLang('en')"
            >English (US)</a
          >
        </div>
      </div>
      <div class="dropdown-divider m-0"></div>
      <a class="dropdown-item fw-500 pt-3 pb-3" @click.prevent="doLogout">
        <span data-i18n="drpdwn.page-logout">{{ $t('topbar.profil.logout') }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data: function () {
    return {
      language: {
        fr: 'Français',
        en: 'English',
      },
      lang: this.$i18n.locale,
    }
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
  },
  methods: {
    ...mapActions('auth', ['logout', 'updateLocale']),
    ...mapActions('admin', ['leaveImpersonate']),
    doLogout: function () {
      this.logout().then(() => {
        this.leaveImpersonate().then(() => {
          this.$router.go()
        })
      })
    },
    setLang: function (locale) {
      this.$store.commit('setLang', locale)
      this.updateLocale(locale).then(() => {
        this.lang = this.$i18n.locale
      })
    },
  },
}
</script>
