<template>
  <div class="mb-4">
    <HeaderFormItem
        :instruction="`${
                attribute.instruction ? attribute.instruction : ''
            } ${requiredasterisk}`"
        :required="`${requiredmessage} `"
        :description="`${
                attribute.description ? attribute.description : ''
            } `"
    >
    </HeaderFormItem>
    <!--    inline-flex justify-center justify-items-center items-center-->
    <div class="flex items-center w-full">
      <div class="flex-column w-full">
        <div class="mb-2 flex items-center">
          <i
              :class="
                            attribute.response_private == 1
                                ? 'fas fa-lock px-1'
                                : ' '
                        "
          ></i>
          <label class="text-gray-700 text-base font-bold">
            {{ attribute.label ? attribute.label : '' }}
            {{ asterisk }}
          </label>
        </div>
        <input
            v-model="result"
            type="email"
            :placeholder="
                        attribute.config.placeholder &&
                        attribute.config.placeholder[this.$i18n.locale]
                    "
            class="mailInput appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500"
        />
        <p class="text-gray-700 text-xs mb-2">
          {{ attribute.help ? attribute.help : '' }}
        </p>
      </div>
    </div>

    <b-field :message="message" type="is-danger font-bold text-center">
    </b-field>
  </div>
</template>

<script>
import FormItemMixin from '../FormItemMixin'
import HeaderFormItem from '../HeaderFormItem'

export default {
  name: 'EmailFormItem',
  components: {HeaderFormItem},
  mixins: [FormItemMixin],
  watch: {
    result: function (newValue) {
      this.doSave({
        id: this.attribute.id,
        type_id: this.attribute.form_attribute_type_id,
        value: newValue
      })
    }
  },
  data() {
    return {
      result: ''
    }
  },
  created() {
    if (this.packageForReborn) {
    if (this.shouldInitialize()) {
      this.saveUserAnswer({
        id: this.attribute.id,
        type_id: this.attribute.form_attribute_type_id,
        value: this.$store.state.post.post.attributes[this.attribute.id]
            .value
      }).then(() => {
        this.result =
            this.$store.state.post.post.attributes[
                this.attribute.id
                ].value
      })
    }
    }else{
      this.result = this.$store.state.auth.user
          ? this.$store.state.auth.user.email
          : ''
    }
  },
}
</script>

<style scoped></style>
