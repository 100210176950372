<template>
<div class="" id="dropdownLangue">
    <a href="#" class="header-icon" data-toggle="dropdown" title="Langue" aria-expanded="false">
        {{ this.language[this.$i18n.locale] }} <i class="fal fa-flag"></i>
    </a>
    <div class="dropdown-menu dropdown-menu-animated">
        <a href="#" class="dropdown-item" @click="setLang('fr')">
            <i class="fas fa-wine-bottle "></i>
            Français
        </a>
        <a href="#" class="dropdown-item" @click="setLang('en')">
            <i class="fas fa-flag-usa"></i>
            English
        </a>
    </div>

</div>
</template>

<style scoped>
.header-icon {
    margin-left: 0.4rem;
}

i {
    margin-left: 0.2rem;
    margin-right: 0.4rem;
}
</style>

<script>
export default {
    data: function () {
        return {
            language: {
                'fr': 'Français',
                'en': 'English',
            }
        }
    },
    created() {

    },
    methods: {
        setLang: function (locale) {
			$('#dropdownLangue').dropdown('hide')
            this.$i18n.locale = locale;
            this.moment.locale(locale);
        }
    }
}
</script>
