<template>
  <div class="mb-4">
    <HeaderFormItem
        :instruction="`${
                attribute.instruction ? attribute.instruction : ''
            } ${requiredasterisk}`"
        :description="`${
                attribute.description ? attribute.description : ''
            } `"
        :required="`${requiredmessage} `"
    >
    </HeaderFormItem>
    <div class="flex items-center">
      <div class="flex-column w-full">
        <div class="mb-2 flex items-center">
          <i
              :class="
                            attribute.response_private == 1
                                ? 'fas fa-lock px-1 pb-2'
                                : ' '
                        "
          ></i>

          <label class="text-gray-700 text-base font-bold mb-2">
            {{ attribute.label ? attribute.label : '' }}
            {{ asterisk }}
          </label>
        </div>
        <input
            v-model="result"
            class="textInput appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500"
            type="text"
            :placeholder="
                        attribute.config.placeholder
                            ? attribute.config.placeholder[this.$i18n.locale]
                            : ''
                    "
        />
        <p class="text-gray-700 text-xs mb-2">
          {{ attribute.help ? attribute.help : '' }}
        </p>
      </div>
    </div>

    <b-field :message="message" type="is-danger font-bold text-center">
    </b-field>
  </div>
</template>

<script>
import FormItemMixin from '../FormItemMixin'
import HeaderFormItem from '../HeaderFormItem'

export default {
  name: 'SmallTextFormItem',
  components: {HeaderFormItem},
  mixins: [FormItemMixin],
  data() {
    return {}
  },
  watch: {
    result: function (newValue) {
      this.doSave({
        id: this.attribute.id,
        type_id: this.attribute.form_attribute_type_id,
        value: newValue
      })
    }
  }
}
</script>

<style scoped></style>
