export default [
    {
        path: '/posts/:id',
        name: 'Post',
        component: () => import("@/views/Post/Post.vue"),
    },
    {
        path: '/post/create/forms/:formId',
        name: 'CreatePost',
        component: () => import("@/views/Post/CreatePost.vue"),
    },
    {
        path: '/posts',
        name: 'Data',
        component: () => import("@/views/Post/Posts.vue"),
    },
    {
        path: '/kanban',
        name: 'Kanban',
        component: () => import("@/views/Post/Kanban.vue"),
    },
    {
        path: '/statistiques',
        name: 'Statistiques',
        component: () => import("@/views/Post/Statistiques.vue"),
    },
    {
        path: '/performances',
        name: 'Performances',
        component: () => import("@/views/Post/Performances.vue"),
    },
    {
        path: '/post/create/forms',
        name: 'FormChoice',
        component: () => import("@/views/Post/FormChoice.vue"),
    },
    {
        path: '/post/:postId/edit',
        name: 'EditPost',
        component: () => import("@/views/Post/EditPost.vue"),
    },
    {
        path: '/map',
        name: 'TicketMap',
        component: () => import("@/views/Post/TicketMap.vue"),
    },
];
