export default {
    setCompanies(state, data) {
        state.companies = data
    },
    setStatuses(state, data) {
        state.statuses = data
    },
    setCompany(state, data) {
        state.company = data
    },
    setCompaniesFilters(state, data) {
        state.companiesFilters = data
    },
    setNewCompany(state, data) {
        state.newCompany = data
    },
    setTemplates(state, data) {
        state.templates = data
    },
    deleteTemplate(state, data) {
        let newTemplates = []
        state.templates.forEach((template) => {
            if (template !== data) {
                newTemplates.push(template)
            }
        })
        state.templates = newTemplates
    },
    setTemplatesFilters(state, data) {
        state.templatesFilters = data
    },
    addTemplate(state, data) {
        state.templates.push(data)
    },
    setTemplate(state, data) {
        state.template = data
    },
    editTemplate(state, data) {
        state.template = data
        state.templates[data.id - 1] = data
    },
    setPagination(state, data) {
        state.pagination = data
    },
    setImpersonation(state, data) {
        state.isUnderImpersonation = true
        state.userImpersonated = data.user
    },
    leaveImpersonation(state) {
        state.isUnderImpersonation = false
        state.userImpersonated = null
    }
}
