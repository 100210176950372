import i18n from '@/i18n/i18n'

export default {
    fire({ state }, data) {
        state.toaster[data.type](data.message, data.title)
    },
    showValidationError({ state }, data) {
        state.toaster.options['timeOut'] = 0

        var errors = []

        for (const [key, value] of Object.entries(data.errors)) {
            let traduction = i18n.messages[i18n.locale]

            for (let i = 0; i < value.length; i++) {
                value[i] === 'validation.required'
                    ? (value[i] = traduction.error.validation.required)
                    : (value[i] = value[i])
            }
            errors.push(`${key}: ${value}`)
        }

        state.toaster['error'](errors.join('<br/>'), data.message)
    }
}
