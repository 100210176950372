import axios from 'axios'
axios.defaults.headers.common['Accept'] = 'application/json'

export default {
  getStatus({ rootState, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootState.baseURL}/statuses`)
        .then((response) => {
          commit('setStatuses', response.data.statuses)
          dispatch('createFormTypeStatusObject')
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createFormTypeStatusObject(context) {
    let attributes={}
    context.state.statuses.forEach((formType) => {
      attributes[formType.id] = []
      formType.statuses.forEach((status) => {
        attributes[formType.id].push(status) 
      })
    })
    context.commit('setFormTypeStatuses',attributes)
  },
}
