export default [
    {
        path: '/company/users',
        name: 'CompanyUsers',
        component: () => import('@/views/Company/Users.vue')
    },
    {
        path: '/company/user/create',
        name: 'CreateCompanyUser',
        component: () => import('@/views/Company/UserBuilder.vue')
    },
    {
        path: '/company/user/:userId/edit',
        name: 'EditCompanyUser',
        component: () => import('@/views/Company/UserBuilder.vue')
    },
    {
        path: '/company/webhooks',
        name: 'CompanyWebhooks',
        component: () => import('@/views/Company/Webhooks.vue')
    },
    {
        path: '/company/webhooks/create',
        name: 'CreateCompanyWebhook',
        component: () => import('@/views/Company/WebhookBuilder.vue')
    },
    {
        path: '/company/webhooks/:webhookId/edit',
        name: 'EditCompanyWebhook',
        component: () => import('@/views/Company/WebhookBuilder.vue')
    },
    {
        path: '/company/settings',
        name: 'CompanySettings',
        component: () => import('@/views/Company/Settings.vue')
    }
]
